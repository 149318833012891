import React, { useState, useEffect, useContext, useRef } from "react";
import { GlobalContext } from "./Cars";
import fields from "./fields";
import utilities from "./UtilityFunctions"

function CarPayments({ DealAgreementNo, setCarData, parentSheetName, parentPKeyName, parentPKeyValue }){
    const ownSheetName = "CarPayments";
    const globals = useContext(GlobalContext);
    const { socket } = globals; 
    const { toggleMessageBox } = globals;
    const [ payments, setPayments ] = useState([]); 
    const [ currPaymentIndex, setCurrPaymentIndex ] = useState(1);
    const [ modalVisible, setModalVisibility ] = useState({
        visible: false,
        mode: "edit"
    });
    const [ fetchSwitch, setFetchSwitch ] = useState(true);

    const openModal = (paymentID)=>{
        setCurrPaymentIndex(paymentID);
        setModalVisibility({
            visible: true,
            mode: "edit"
        });
    }

    const fetchpayments = async (_DealAgreementNo)=>{
        try {
            const res = await fetch("https://api.autodealerug.com/getpaymentsforonecar", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify({
                    DealershipID: globals.DealershipID,
                    DealAgreementNo: _DealAgreementNo
                })
            });
    
            const responsePayload = await res.json()
    
            const returnObj = {};
            
            if(res.ok) { //2XX
                returnObj.ok = true;
                returnObj.message = responsePayload.data;
                //console.log(returnObj);
                return returnObj;
            } else { //4XX, 5XX TODO: Handle specifics later. Log for now
                console.log("Error: " + responsePayload.message);
                returnObj.ok = false;
                returnObj.message = responsePayload.message;
                console.log(returnObj)
                return returnObj;
            }
        } catch(err) { //Probably the network. TODO: Handle the specifics
            console.log(err.message);
            toggleMessageBox({
                on: true,
                title: "Error",
                message: err.message,
                buttons: ["OK"],
                clicked: ""
            })   
        }
    }

    const addRecordListener = (msg)=>{
        console.log(JSON.stringify(msg));
        if(msg.sheetName!==ownSheetName || msg.ok===false) return;

        setPayments(prev=>{
            return [...prev, msg.newRecord];
        });
    }

    const fieldUpdateListener = (msg)=>{
        if(msg.sheetName!==ownSheetName || msg.ok===false){
            //Only handle events for this sheet
            return;
          }
        console.log(msg);
        //Get the Field and Status {ok: true/false, fieldName: fieldName}
        if(msg.ok){ //Update was successful
            //Update the fallback, then refresh
            setPayments(prev=>{
                return prev.map(row=>{
                    if(row[msg.primaryKeyName]===msg.primaryKeyValue){
                        return {...row, [msg.fieldName]: msg.fieldValue}
                    }
                    return row;
                });
            });
        } 
    }


    useEffect(()=>{
        //Fetch CarPayments for this car using its DealAgreementNo
        fetchpayments( DealAgreementNo ).then(
            (jsonObj)=>{
                if(jsonObj.ok){ //Data, baby! Set the state
                    setPayments(jsonObj.message);
                } else {
                    toggleMessageBox({
                        on: true,
                        title: "Error",
                        message: jsonObj.message,
                        buttons: ["OK"],
                        clicked: ""
                    })
                }
            }
        ).catch(
            (err)=>{
                toggleMessageBox({
                    on: true,
                    title: "Error",
                    message: err.message,
                    buttons: ["OK"],
                    clicked: ""
                })
            }
        )
        socket.on('add_record', addRecordListener);
        socket.on('update_row', fieldUpdateListener);
        return ()=>{
            socket.off('add_record', addRecordListener);
            socket.off('update_row', fieldUpdateListener);
        }
    },[fetchSwitch])

    const addNewPayment = (e)=>{
        e.preventDefault();
        //Open the modal in "add mode"
        setModalVisibility({
            visible: true,
            mode: "add"
        });
    }

    return(
        <div className="other-exps-table-container">
            <table className="table table-striped table-hover table-sm" >
                <thead>
                    <tr>
                        {
                            payments && payments[0] && Object.keys(payments[0]).map((val, ind,arr)=>{
                                if(fields[val].public){
                                    return (
                                        <th key={val}>
                                            {fields[val].displayName}
                                        </th>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                        {
                            payments && payments.map((obj, rowindex, arr)=>{
                                return (<tr key={rowindex}>
                                    {
                                        Object.keys(obj).map((propty, fieldindex,arrd)=>{
                                            if(fields[propty].public){
                                                if(propty==="Amount"){
                                                    return(
                                                        <td key={fieldindex}>
                                                            <a href="#" onClick={(e)=>{
                                                                e.preventDefault();
                                                                return openModal(rowindex)
                                                            }}>
                                                            {utilities.formatIntoInput(obj[propty], fields[propty].dataType)}
                                                            </a>
                                                        </td>
                                                    );
                                                } else {
                                                    return(
                                                        <td key={fieldindex}>
                                                            {utilities.formatIntoInput(obj[propty], fields[propty].dataType)}
                                                        </td>
                                                    );
                                                }

                                            }
                                        })
                                    }
                                </tr>)
                            })
                        }
                </tbody>
            </table>
            <div className="btn-container">
                <button onClick={addNewPayment} className="btn btn-secondary">
                    Add Payment
                </button>
            </div>
            {
                modalVisible.visible &&  <AddEditPayment 
                mode={modalVisible.mode}
                initPaymentData={payments[currPaymentIndex]}
                toggleModal={()=>setModalVisibility({
                    visible: false,
                    mode: ""
                })}
                DealAgreementNo={DealAgreementNo}
                ownSheetName={ownSheetName}
                parentSheetName={parentSheetName}
                parentPKeyName={parentPKeyName}
                parentPKeyValue={parentPKeyValue}
                 />
            }
        </div>
    )
}

/**************************************************************************
 *************************************************************************/

function AddEditPayment({ mode, initPaymentData, toggleModal, DealAgreementNo, ownSheetName, parentSheetName, parentPKeyName, parentPKeyValue }){
    const [ backupPaymentData, setBackupPaymentData ] = useState((()=>{
        if(mode==="add"){
            return (
                {
                    PaymentDate: utilities.formatIntoInput((new Date).toISOString(), "date"),
                    Amount: 0,
                    Remarks: "",
                    Currency: "UGX",
                    PaymentMethodID: 1,
                    AdvAr: "ADV"
                }
            );
        } else {
            Object.keys(initPaymentData).forEach((fieldName, value, array)=>{
                initPaymentData[fieldName] = utilities.formatFromInput(initPaymentData[fieldName], fields[fieldName].dataType);
            });
            return initPaymentData;
        }
    })()); //Format the fields
    const [ paymentData, setPaymentData ] = useState(backupPaymentData);
    const [ paymentmethods, setPaymentMethods ] = useState([]);
    const pendingMessages = useRef([]);
    const { sendOrQueue } = utilities

    const inputClassList = "form-control";
    const inputWrongClassList = "form-control form-control-wrong";

    //Get the global socket
    const globals = useContext(GlobalContext);
    const { toggleMessageBox } = globals;
    const socket = globals.socket;
    const table_name = "CarPayments";
    const primary_key = "PaymentID";

    const handleSubmit = (e)=>{
        //e.preventDefault();
        //In add mode, send a fetch request
        //In edit mode, use websockets. Change button to "Save And Close"
        if(mode==="add"){
            //First check that the necessary data was
            if(!(paymentData.PaymentDate && paymentData.Amount)){
                toggleMessageBox({
                    on: true,
                    title: "Error",
                    message: "Please enter all required fields",
                    buttons: ["OK"],
                    clicked: ""
                })
                return;
            }            

            fetch("https://api.autodealerug.com/addcarpayment", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify({
                    ...paymentData,
                    DealershipID: globals.DealershipID,
                    UserID: globals.userData.UserID,
                    DealAgreementNo,
                    ownSheetName,
                    parentSheetName,
                    parentPKeyName: "DealAgreementNo",
                    parentPKeyValue: DealAgreementNo
                })
            }).then((res)=>{
                if(res.ok) { //2XX
                    //Close the modal
                    console.log(res.message);
                    toggleModal(); //And refresh
                } else { //4XX, 5XX TODO: Handle specifics later. Log for now
                    console.log("Error: " + JSON.stringify(res));
                    toggleMessageBox({
                        on: true,
                        title: "Server Error",
                        message: res.message,
                        buttons: ["OK"],
                        clicked: ""
                    })
                }
            }).catch((err)=>{
                toggleMessageBox({
                    on: true,
                    title: "Network error or server down",
                    message: err.message,
                    buttons: ["OK"],
                    clicked: ""
                })
            })
        } else { //Just close
            toggleModal();
        }
    }

    const getPaymentMethods = ()=>{
        fetch("https://api.autodealerug.com/getpaymentmethods",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            }
        ).then((res)=>{
            if(res.ok){
                return res.json()
            } else { //Server error
                return res.json().then((error) => {
                    throw new Error("Server Error: " + (error.message || "Unknown error"));
                });
            }
        }).then((resultObj)=>{
            setPaymentMethods(resultObj.data);
        })
        .catch((err)=>{ //Network error
            // Handle network or parsing errors
            console.error("Fetch error:", err);
        })
    }

    const handleChange = ({ target })=>{
        //Check against the regex if it exists
        const regexx = fields[target.name].regex || null
        if(regexx && !(new RegExp(regexx)).test(target.value)){ //If it fails the regex test
            target.className = inputWrongClassList;
        } else {
            target.className = inputClassList;
            setPaymentData((prev)=>{
                return {...prev, [target.name]: utilities.formatFromInput(target.value, fields[target.name].dataType)}
            })
        }
    }

    const handleBlur = ({ target })=>{
                //In add mode: Set the state. 
        //In edit mode: update with websockets AND set the state
        if(mode==="add"){
            setPaymentData((prev)=>{
                return {...prev, [target.name]: utilities.formatFromInput(target.value, fields[target.name].dataType)}
            })
        } else {
            //If it's a number, remove commas
            let newVal = utilities.formatFromInput(target.value, fields[target.name].dataType);
            let prevVal = utilities.formatFromInput(backupPaymentData[target.name], fields[target.name].dataType);
            //console.log(`New val: ${newVal}, Prev val: ${prevVal}`);
            if(newVal!=prevVal) { //It has been changed
                const msg = {
                    DealershipID: globals.DealershipID,
                    UserID: globals.userData.UserID,
                    ownSheetName: ownSheetName,
                    parentSheetName: parentSheetName,
                    parentPKeyName: parentPKeyName,
                    parentPKeyValue: parentPKeyValue,
                    table: table_name,
                    primaryKeyName: primary_key,
                    primaryKeyValue: paymentData.PaymentID,
                    fieldName: target.name,
                    fieldValue: newVal,
                    triggerRefresh: fields[target.name].triggerRefresh
                };

                sendOrQueue(msg, socket, pendingMessages.current, paymentData, backupPaymentData, setPaymentData);
            }
        }
    }

    const handleCancel = ()=>{
        toggleModal();
    }
    const fieldUpdateListener = (msg)=>{
        if(msg.sheetName!==ownSheetName || msg.ok===false){
            //Only handle events for this sheet
            return;
          }
        console.log(msg);
        //Get the Field and Status {ok: true/false, fieldName: fieldName}
        if(msg.ok){ //Update was successful
            //Update the fallback, then refresh
            setBackupPaymentData({...backupPaymentData, [msg.fieldName]: paymentData[msg.fieldName]});
        } else { //Check and log msg.error
            //Show error and revert to fallback
            setPaymentData({...paymentData, [msg.fieldName]:backupPaymentData[msg.fieldName]});
        }
    }
    const ioReconnectListener = ()=>{
        console.log("reconnected")
        let attempts = 10;
        let currMsg = {};
        //Check the queue for any messages for transmission
        while(pendingMessages.current.length>0) {
            console.log(`${pendingMessages.current.length} messages in queue`);
            //Limit no. of attempts to 100 to prevent endless loop
            currMsg = pendingMessages.current.shift();
            sendOrQueue(currMsg, socket, pendingMessages.current, paymentData, backupPaymentData, setPaymentData);
            if(attempts===0){
                break;
            }
            attempts--;
        }
    }
    useEffect(()=>{
        //Add event listeners to the websocket
        //Return a function to remove them when component dismounts
        if(socket.disconnected){
            socket.connect()
        }
        socket.on('update_record', fieldUpdateListener);
        socket.on('connect',ioReconnectListener);
        getPaymentMethods();

        return ()=>{
            socket.off('update_record', fieldUpdateListener);
            socket.off('connect',ioReconnectListener);
        }
    },[]);


    return (
        <div className="form-modal top-tier">
            <div className="add-payment-dialogue">
                <div className="form-payments">
                    <div className="input-pair">
                        <label required htmlFor="PaymentDate">{fields.PaymentDate.displayName}:</label>
                        <input id="PaymentDate" onBlur={handleBlur} className="form-control" onChange={handleChange} type="date" name="PaymentDate" value={paymentData.PaymentDate}></input>
                    </div>
                    <div className="input-pair">
                        <label required htmlFor="Amount">{fields.Amount.displayName}:</label>
                        <input id="Amount" onBlur={handleBlur} className="form-control" onChange={handleChange} type="text" name="Amount" value={utilities.formatIntoInput(paymentData.Amount, fields.Amount.dataType)}></input>
                    </div>
                    <div className="input-pair">
                        <label htmlFor="Remarks">{fields.Remarks.displayName}:</label>
                        <input id="Remarks" onBlur={handleBlur} className="form-control" onChange={handleChange} type="text" name="Remarks" value={paymentData.Remarks}></input>
                    </div>
                    <div className="input-pair">
                        <label htmlFor="AdvAr">ADV/AR:</label>
                        <select id="AdvAr" onBlur={handleBlur} name="AdvAr" className="form-control" onChange={handleChange} value={paymentData.AdvAr}>
                            <option value="ADV">ADV</option>
                            <option value="AR">AR</option>
                        </select>
                    </div>

                    <div className="input-pair">
                        <label htmlFor="PaymentMethodID">Payment Method:</label>
                        <select id="PaymentMethodID" onBlur={handleBlur} name="PaymentMethodID" className="form-control" onChange={handleChange} value={paymentData.PaymentMethodID}>
                            {
                                paymentmethods.map((val)=>{
                                    return(
                                        <option value={val.PaymentMethodID} key={val.PaymentMethodID}>
                                            {val.PaymentMethod}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>

                    <div className="frm-btn-container">
                        <button className="btn btn-secondary"
                            onClick={handleCancel}
                        >Cancel</button>

                        <button className="btn btn-secondary"
                            onClick={handleSubmit}
                        >{mode==="add"?"Submit":"Save And Close"}</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CarPayments;