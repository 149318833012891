import React, { useState, useEffect, useContext, useRef } from "react";
import { GlobalContext } from "./Cars";
import fields from "./fields";
import utilities from "./UtilityFunctions";
import CommonThead from "./CommonThead";
import { passesFilter } from "./UtilityFunctions";
import CommonTBody from "./CommonTBody";

function OtherExpenses({ VehicleID, parentSheetName, parentPKeyName, parentPKeyValue }){
    const ownSheetName = "OtherExpenses";
    const globals = useContext(GlobalContext);
    //Get the global socket
    const socket = globals.socket;
    const { toggleMessageBox } = globals;
    const [ expenses, setExpenses ] = useState([]); 
    const [ currExpenseIndex, setCurrExpenseIndex ] = useState(1);
    const [ modalVisible, setModalVisibility ] = useState({
        visible: false,
        mode: "edit"
    });
/*    
    //It works, but only for current user.
    //I want an a new record to be sent from the server to all users
    useEffect(()=>{
        //Total up all the amounts in expenses and set it as the parent's "OtherExpenses"
        let totalAmount = 0;
        expenses.forEach((valObj)=>{
            totalAmount+=Number(valObj.Amount);
        });
        
        setCarData((prev)=>{
            return {...prev, OtherExpenses: totalAmount}
        })
    },[expenses]);
*/
    const openModal = (expenseID)=>{
        setCurrExpenseIndex(expenseID);
        setModalVisibility({
            visible: true,
            mode: "edit"
        });
    }

    const [ toggleReload, setToggleReload ] = useState(true);

    const [ sortOrder, setSortOrder ] = useState(()=>{
        const sortObj = {};
        expenses && expenses[0] && Object.keys(expenses[0]).forEach((propertyName)=>{
            sortObj[propertyName] = "none"; //Other values will be "asc" and "desc"
        });
        return sortObj;
    })
    
    const [ filterObject, setFilterObject ] = useState({});

    const sortExpenses = (field)=>{
        setSortOrder((prev)=>{
            setExpenses((prevExpenses)=>{
                return  utilities.sortArray(prevExpenses, field, prev[field]==="asc"?"desc":"asc")
            });
            return {...prev, [field]: prev[field]==="asc"?"desc":"asc"}
        })
        setToggleReload(prev=>!prev);
    }

    const filterData = (fieldName, filterType, lowerValue, upperValue)=>{
        if(!filterType) return {}; //This way, just call with only first argument to clear the filter
        setFilterObject(prev=>{
            return {...prev, [fieldName]: { filterType, lowerValue, upperValue }}
        });
    }

    const FieldOrder = useContext(GlobalContext).fieldOrder;
    const fieldOrderInitializer = ()=>{
        const tmpFieldOrder = FieldOrder[ownSheetName];
        if(!tmpFieldOrder?.length){ //0 or undefined. Build one
            let ownFields = expenses && expenses[0] && Object.getOwnPropertyNames(expenses[0]); //Fields of first row
            ownFields = ownFields?ownFields:[]; //If undefined, return an empty array
            const defaultFieldOrder = ownFields.map((strField)=>{
                //Return an object {name:name,hidden:false}
                return {name: strField, hidden: false};
            });
            return defaultFieldOrder;
        }
        return tmpFieldOrder;
    }
    const [ fieldOrder, setFieldOrder ] = useState(fieldOrderInitializer); //Get initial from globals

    const fetchexpenses = async (_VehicleID)=>{
        try {
            const res = await fetch("https://api.autodealerug.com/getotherexpenses", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify({
                    DealershipID: globals.DealershipID,
                    VehicleID: _VehicleID
                })
            });
    
            const responsePayload = await res.json()
    
            const returnObj = {};
            
            if(res.ok) { //2XX
                returnObj.ok = true;
                returnObj.message = responsePayload.data;
                //console.log(returnObj);
                return returnObj;
            } else { //4XX, 5XX TODO: Handle specifics later. Log for now
                console.log("Error: " + responsePayload.message);
                returnObj.ok = false;
                returnObj.message = responsePayload.message;
                console.log(returnObj)
                return returnObj;
            }
        } catch(err) { //Probably the network. TODO: Handle the specifics
            console.log(err.message);
            toggleMessageBox({
                on: true,
                title: "Error",
                message: err.message,
                buttons: ["OK"],
                clicked: ""
            })   
        }
    }
    const addRecordListener = (msg)=>{
        //console.log(JSON.stringify(msg));
        if(msg.sheetName!==ownSheetName || msg.ok===false) return;
        console.log("Adding new expense");
        setExpenses(prev=>{
            return [...prev, msg.newRecord];
        });
    }

    const fieldUpdateListener = (msg)=>{
        if(msg.sheetName!==ownSheetName || msg.ok===false){
            //Only handle events for this sheet
            return;
          }
        console.log(msg);
        //Get the Field and Status {ok: true/false, fieldName: fieldName}
        if(msg.ok){ //Update was successful
            //Update the fallback, then refresh
            setExpenses(prev=>{
                return prev.map(row=>{
                    if(row[msg.primaryKeyName]===msg.primaryKeyValue){
                        return {...row, [msg.fieldName]: msg.fieldValue}
                    }
                    return row;
                });
            });
        } 
    }

    useEffect(()=>{
        //Fetch OtherExpenses for this car using its VehicleID
        fetchexpenses( VehicleID ).then(
            (jsonObj)=>{
                if(jsonObj.ok){ //Data, baby! Set the state
                    setExpenses(jsonObj.message);
                } else {
                    toggleMessageBox({
                        on: true,
                        title: "Error",
                        message: jsonObj.message,
                        buttons: ["OK"],
                        clicked: ""
                    })
                }
            }
        ).catch(
            (err)=>{
                toggleMessageBox({
                    on: true,
                    title: "Error",
                    message: err.message,
                    buttons: ["OK"],
                    clicked: ""
                })
            }
        )
        socket.on('add_record', addRecordListener);
        socket.on('update_record', fieldUpdateListener)
        return ()=>{
            socket.off('add_record', addRecordListener);
            socket.off('update_record', fieldUpdateListener);
        }
    },[])
    useEffect(()=>{
        setFieldOrder(fieldOrderInitializer);
    }, [expenses]);
    const addNewExpense = (e)=>{
        e.preventDefault();
        //Open the modal in "add mode"
        setModalVisibility({
            visible: true,
            mode: "add"
        });
    }

    const [ selectedRow, setSelectedRow ] = useState("")
    const selectRow = (rowIndex)=>{
        console.log("Selected Row: ",rowIndex)
        setSelectedRow(rowIndex)
    }

    return(
        <div className="other-exps-table-container">
            <table className="table table-striped table-hover table-sm" >
                <CommonThead
                    objectsArray={expenses}
                    sortObjectsArray={sortExpenses}
                    sortOrder={sortOrder}
                    fieldOrder={fieldOrder}
                    setFieldOrder={setFieldOrder}
                    parentSheetName={ownSheetName}
                    filterData={filterData}
                    filterObject={filterObject}
                />
                <CommonTBody
                    objectsArray={expenses}
                    filterObject={filterObject}
                    selectRow={selectRow}
                    selectedRow={selectedRow}
                    fieldOrder={fieldOrder}
                    openModalEdit={openModal}
                    clickableField={"ExpenseName"}
                    primaryKeyName={"ExpenseID"}
                />
            </table>
            <div className="btn-container">
                <button onClick={addNewExpense} className="btn btn-secondary">
                    Add Expense
                </button>
            </div>
            {
                modalVisible.visible &&  <AddEditExpense 
                mode={modalVisible.mode}
                initExpenseData={expenses[currExpenseIndex]}
                toggleModal={()=>setModalVisibility({
                    visible: false,
                    mode: ""
                })}
                VehicleID={VehicleID}
                ownSheetName={ownSheetName}
                parentSheetName={parentSheetName}
                parentPKeyName={parentPKeyName}
                parentPKeyValue={parentPKeyValue}
                 />
            }
        </div>
    )
}

/**************************************************************************
 *************************************************************************/

function AddEditExpense({ mode, initExpenseData, toggleModal, VehicleID, ownSheetName, parentSheetName, parentPKeyName, parentPKeyValue }){
    const [ backupExpenseData, setBackupExpenseData ] = useState((()=>{
        if(mode==="add"){
            return (
                {
                    ExpenseName: "",
                    PaymentDate: utilities.formatIntoInput((new Date).toISOString(), "date"),
                    Amount: 0,
                    Details: "",
                    //TODO: UserID: globals?.UserID
                }
            );
        } else {
            Object.keys(initExpenseData).forEach((fieldName, value, array)=>{
                initExpenseData[fieldName] = utilities.formatFromInput(initExpenseData[fieldName], fields[fieldName].dataType);
            });
            return initExpenseData;
        }
    })()); //Format the fields
    const [ expenseData, setExpenseData ] = useState(backupExpenseData);
    const pendingMessages = useRef([]);
    const { sendOrQueue } = utilities

    const inputClassList = "form-control";
    const inputWrongClassList = "form-control form-control-wrong";

    //Get the global socket
    const globals = useContext(GlobalContext);
    const { toggleMessageBox } = globals;
    const socket = globals.socket;
    const table_name = "OtherExpenses";
    const primary_key = "ExpenseID";

    const handleSubmit = (e)=>{
        //e.preventDefault();
        //In add mode, send a fetch request
        //In edit mode, use websockets. Change button to "Save And Close"
        if(mode==="add"){
            //First check that the necessary data was
            if(!(expenseData.ExpenseName && expenseData.PaymentDate && expenseData.Amount)){
                toggleMessageBox({
                    on: true,
                    title: "Error",
                    message: "Please enter all required fields",
                    buttons: ["OK"],
                    clicked: ""
                })
                return;
            }            
            fetch("https://api.autodealerug.com/addexpense", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify({
                    ...expenseData,
                    DealershipID: globals.DealershipID,
                    UserID: globals.userData.UserID,
                    VehicleID,
                    ownSheetName,
                    parentSheetName,
                    parentPKeyName: "VehicleID",
                    parentPKeyValue: VehicleID
                })
            }).then((res)=>{
                if(res.ok) { //2XX
                    //Close the modal
                    console.log(res.message);
                    toggleModal(); //And refresh
                } else { //4XX, 5XX TODO: Handle specifics later. Log for now
                    console.log("Error: " + JSON.stringify(res));
                    toggleMessageBox({
                        on: true,
                        title: "Server Error",
                        message: res.message,
                        buttons: ["OK"],
                        clicked: ""
                    })
                }
            }).catch((err)=>{
                toggleMessageBox({
                    on: true,
                    title: "Network error or server down",
                    message: err.message,
                    buttons: ["OK"],
                    clicked: ""
                })
            })
        } else { //Just close
            toggleModal();
        }
    }

    const handleChange = ({ target })=>{
        //Check against the regex if it exists
        const regexx = fields[target.name].regex || null
        if(regexx && !(new RegExp(regexx)).test(target.value)){ //If it fails the regex test
            target.className = inputWrongClassList;
        } else {
            target.className = inputClassList;
            setExpenseData((prev)=>{
                return {...prev, [target.name]: utilities.formatFromInput(target.value, fields[target.name].dataType)}
            })
        }
    }

    const handleBlur = ({ target })=>{
                //In add mode: Set the state. 
        //In edit mode: update with websockets AND set the state
        if(mode==="add"){
            setExpenseData((prev)=>{
                return {...prev, [target.name]: utilities.formatFromInput(target.value, fields[target.name].dataType)}
            })
        } else {
            //If it's a number, remove commas
            let newVal = utilities.formatFromInput(target.value, fields[target.name].dataType);
            let prevVal = utilities.formatFromInput(backupExpenseData[target.name], fields[target.name].dataType);
            //console.log(`New val: ${newVal}, Prev val: ${prevVal}`);
            if(newVal!=prevVal) { //It has been changed
                const msg = {
                    DealershipID: globals.DealershipID,
                    UserID: globals.userData.UserID,
                    ownSheetName: ownSheetName,
                    parentSheetName: parentSheetName,
                    parentPKeyName: parentPKeyName,
                    parentPKeyValue: parentPKeyValue,
                    table: table_name,
                    primaryKeyName: primary_key,
                    primaryKeyValue: expenseData.ExpenseID,
                    fieldName: target.name,
                    fieldValue: newVal,
                    triggerRefresh: fields[target.name].triggerRefresh
                };

                sendOrQueue(msg, socket, pendingMessages.current, expenseData, backupExpenseData, setExpenseData);
            }
        }

    }

    const handleCancel = ()=>{
        toggleModal();
    }

    const fieldUpdateListener = (msg)=>{
        if(msg.sheetName!==ownSheetName || msg.ok===false){
            //Only handle events for this sheet
            return;
          }
        console.log(msg);
        //Get the Field and Status {ok: true/false, fieldName: fieldName}
        if(msg.ok){ //Update was successful
            //Update the fallback, then refresh
            setBackupExpenseData({...backupExpenseData, [msg.fieldName]: expenseData[msg.fieldName]});
        } else { //Check and log msg.error
            //Show error and revert to fallback
            setExpenseData({...expenseData, [msg.fieldName]:backupExpenseData[msg.fieldName]});
        }
    }
    const ioReconnectListener = ()=>{
        console.log("reconnected")
        let attempts = 10;
        let currMsg = {};
        //Check the queue for any messages for transmission
        while(pendingMessages.current.length>0) {
            console.log(`${pendingMessages.current.length} messages in queue`);
            //Limit no. of attempts to 100 to prevent endless loop
            currMsg = pendingMessages.current.shift();
            sendOrQueue(currMsg, socket, pendingMessages.current, expenseData, backupExpenseData, setExpenseData);
            if(attempts===0){
                break;
            }
            attempts--;
        }
    }
    useEffect(()=>{
        //Add event listeners to the websocket
        //Return a function to remove them when component dismounts
        if(socket.disconnected){
            socket.connect()
        }
        socket.on('update_record', fieldUpdateListener);

        socket.on('connect',ioReconnectListener);

        return ()=>{
            socket.off('update_record', fieldUpdateListener);
            socket.off('connect',ioReconnectListener);
        }
    },[]);


    return (
        <div className="form-modal top-tier">
            <div className="add-expense-dialogue">
                <div className="form-other-expenses">
                    <label htmlFor="ExpenseName">{fields.ExpenseName.displayName}</label>
                    <input onBlur={handleBlur} className="form-control" onChange={handleChange} required type="text" name="ExpenseName" value={utilities.formatIntoInput(expenseData.ExpenseName, fields.ExpenseName.dataType)}></input>

                    <label required htmlFor="PaymentDate">{fields.PaymentDate.displayName}</label>
                    <input onBlur={handleBlur} className="form-control" onChange={handleChange} type="date" name="PaymentDate" value={expenseData.PaymentDate}></input>

                    <label required htmlFor="Amount">{fields.Amount.displayName}</label>
                    <input onBlur={handleBlur} className="form-control" onChange={handleChange} type="text" name="Amount" value={utilities.formatIntoInput(expenseData.Amount, fields.Amount.dataType)}></input>

                    <label htmlFor="Details">{fields.Details.displayName}</label>
                    <input onBlur={handleBlur} className="form-control" onChange={handleChange} type="text" name="Details" value={expenseData.Details}></input>
                    
                    <div className="frm-btn-container">
                        <button className="btn btn-secondary"
                            onClick={handleCancel}
                        >Cancel</button>

                        <button className="btn btn-secondary"
                            onClick={handleSubmit}
                        >{mode==="add"?"Submit":"Save And Close"}</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default OtherExpenses;