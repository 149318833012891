import React, { useState, useEffect, useContext } from 'react';
import { LineChart, Grid, Card, Title, Text, Metric } from "@tremor/react";
import DashGrid from './DashGrid';

function Dashboard({ Inventory, Committed, Cash, Credit }){
    const [ data, setData ] = useState([])
    useEffect(()=>{
        console.log(Cash);
        setData([
        {
            "id": "Inventory",
            "label": "Inventory",
            "value": `${Inventory.length}`,
            "color": "hsl(143, 70%, 50%)"
        },{
            "id": "Committed",
            "label": "Committed",
            "value": `${Committed.length}`,
            "color": "hsl(292, 70%, 50%)"
        },{
            "id": "Cash",
            "label": "Cash",
            "value": `${Cash.length}`,
            "color": "hsl(244, 70%, 50%)"
        },{
            "id": "Credit",
            "label": "Credit",
            "value": `${Credit.length}`,
            "color": "hsl(340, 70%, 50%)"
        }
    ]); 
    },[Inventory, Committed, Cash, Credit]);

    return (

        <div id='dashboard'>
            <DashGrid />
        </div>
    )
}


export default Dashboard;

