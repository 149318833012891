import React, { useState, useEffect, useContext } from "react";
import AddEditCustomer from "./AddEditCustomer";
import fields from "./fields"
import utilities from "./UtilityFunctions";
import CommonThead from "./CommonThead";
import { GlobalContext } from "./Cars";
import CommonTBody from "./CommonTBody";
import { Button } from "@radix-ui/themes";

function Customers({ customers=[], setCustomers }){
    const ownSheetName = "Customers";
    const [ modalVisible, setModalVisibility ] = useState({
        visible: false,
        mode: "add"
    });
    const [ toggleReload, setToggleReload ] = useState(true);
    const [ sortOrder, setSortOrder ] = useState(()=>{
        const sortObj = {};
        Object.keys(fields).forEach((propertyName)=>{
            sortObj[propertyName] = "none"; //Other values will be "asc" and "desc"
        });
        return sortObj;
    })
    const sortCustomers = (field)=>{
        setSortOrder((prev)=>{
            setCustomers((prevCustomers)=>{
                return  utilities.sortArray(prevCustomers, field, prev[field]==="asc"?"desc":"asc")
            });
            return {...prev, [field]: prev[field]==="asc"?"desc":"asc"}
        })
        setToggleReload(prev=>!prev);
    }
    const [ filterObject, setFilterObject ] = useState({});
    const filterData = (fieldName, filterType, lowerValue, upperValue)=>{
        console.log(fieldName, filterType, lowerValue, upperValue);
        setFilterObject(prev=>{
            return {...prev, [fieldName]: { filterType, lowerValue, upperValue }}
        });
    }
    return (
        <div className="page">
            <Content 
                modalVisible={modalVisible} 
                setModalVisibility={setModalVisibility} 
                customers={customers} 
                setCustomers={setCustomers} 
                sortCustomers={sortCustomers}  
                sortOrder={sortOrder}
                parentSheetName={ownSheetName}
                filterData={filterData}
                filterObject={filterObject}
            />
            <Summary setModalVisibility={setModalVisibility} customers={customers} />
        </div>
    )
}

function Content({ customers, setCustomers, modalVisible, setModalVisibility, sortCustomers, sortOrder, parentSheetName, filterData, filterObject }){
        const FieldOrder = useContext(GlobalContext).fieldOrder; 
            const [ fieldOrder, setFieldOrder ] = useState((()=>{
                const tmpFieldOrder = FieldOrder[parentSheetName];
                if(!tmpFieldOrder?.length){ //0 or undefined. Build one
                    let ownFields = customers && customers[0] && Object.getOwnPropertyNames(customers[0]); //Fields of first row
                    ownFields = ownFields?ownFields:[]; //If undefined, return an empty array
        
                    const defaultFieldOrder = ownFields.map((strField)=>{
                        //Return an object {name:name,hidden:false}
                        return {name: strField, hidden: false};
                    });
                    
                    return defaultFieldOrder;
                }
                return tmpFieldOrder;
            })()); //Get initial from globals
    const [ currentCustomerIndex, setCurrentCustomer ] = useState();
    const openModalEdit = (customerId)=>{
        setCurrentCustomer(customerId);
        setModalVisibility({
            visible: true,
            mode: "edit"
        });
    }
    const updateCustomersLocally = (newCustomer)=>{ //Only update the customer which was updated in the modal
        setCustomers((prev)=>{
            prev.splice(currentCustomerIndex, 1, newCustomer);
            return prev;
        });
    }

    const [ selectedRow, setSelectedRow ] = useState("")

    const selectRow = (rowIndex)=>{
        setSelectedRow(rowIndex)
    }

    return (
        <div className="content">
            <table className="table table-striped table-hover table-sm" >
                <CommonThead
                    objectsArray={customers}
                    sortObjectsArray={sortCustomers}
                    sortOrder={sortOrder}
                    fieldOrder={fieldOrder}
                    setFieldOrder={setFieldOrder}
                    parentSheetName={parentSheetName}
                    filterData={filterData}
                    filterObject={filterObject}
                />
                <CommonTBody
                    objectsArray={customers}
                    filterObject={filterObject}
                    selectRow={selectRow}
                    selectedRow={selectedRow}
                    fieldOrder={fieldOrder}
                    openModalEdit={openModalEdit}
                    clickableField={"CustomerName"}
                    primaryKeyName={"CustomerID"}
                />
            </table>
            {
                modalVisible.visible &&  <AddEditCustomer 
                formMode={modalVisible.mode} 
                showModal={setModalVisibility} 
                customerData={customers[currentCustomerIndex]} 
                updateCustomersLocally={updateCustomersLocally}
                parentSheetName={parentSheetName} />
            }

        </div>
    )
}

function Summary({ setModalVisibility, customers}){
    return (
        <div className="summary">
            <Button
                color="blue"
                variant="outline"
                size="2"
                radius="medium"
                onClick={()=>setModalVisibility({
                    visible: true,
                    mode: "add"
                })}
            >Add New</Button>

            <div className="aggregate">
                <div>Total Number:</div><div>{customers.length}</div>
                <div>Todo:</div><div>Todo</div>
            </div>
        </div>
    )
}

export default Customers;