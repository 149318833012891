import React, { useContext } from "react";
import { GlobalContext } from "./Cars";
import { DropdownMenu, Avatar, Theme } from "@radix-ui/themes";
import UserAccountModal from "./AddEditUserAccount";
import ChangePasswordModal from "./ChangePassword";

function CarsHead(){
    const globals = useContext(GlobalContext);
    const { toggleMessageBox, logOut } = globals;
    const companyName = globals?.companyData?.CompanyName || "Loading...";
    const {FirstName, LastName} = globals.userData;
    const initials = `${FirstName.charAt(0)}${LastName.charAt(0)}`;
    const [  modalVisible, setModalVisibility ] = React.useState({
        visible: false, mode: "edit"
    });
    const [  changePasswordVisible, setChangePasswordVisibility ] = React.useState(false);

    const handleLogOut = async () => {
        try {
            const response = await fetch('https://api.autodealerug.com/logout', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
            });

            if (response.headers?.get('Content-Type')?.includes('application/json')) {
                const resObj = await response.json();

                if(resObj.ok){
                    logOut();
                } else {
                    console.error("Logout failed:", resObj.message);
                    toggleMessageBox({
                        on: true,
                        title: "Error",
                        message: resObj.message,
                        buttons: ["OK"]
                    });
                }
            } else {
                const responseText = await response.text();
                toggleMessageBox({
                    on: true,
                    title: "Error",
                    message: responseText,
                    buttons: ["OK"]
                });
                console.error("Unexpected response format:", responseText);
            }
        } catch (err) {
            toggleMessageBox({
                on: true,
                title: "Error",
                message: "An error occurred while logging out. Please try again." + err.message,
                buttons: ["OK"]
            });
            console.error("Logout failed:", err);
        }
    }

    return (
        <div className="carshead">
            <div className="logo">
            </div>
            <div className="title">
                {companyName}
            </div>
            <div className="user">
                <DropdownMenu.Root>
                    <DropdownMenu.Trigger>
                        <Avatar 
                            size="3"
                            src="https://images.unsplash.com/photo-1607346256330-dee7af15f7c5?&w=64&h=64&dpr=2&q=70&crop=focalpoint&fp-x=0.67&fp-y=0.5&fp-z=1.4&fit=crop"
                            radius="full"
                            fallback={initials}
                            style={{display: "block", margin: "auto"}}
                        />
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content>
                        <DropdownMenu.Item shortcut="" onClick={()=>{setModalVisibility({visible: true, mode: "edit"})}}>Edit Profile</DropdownMenu.Item>
                        <DropdownMenu.Item shortcut="" onClick={()=>setChangePasswordVisibility(true)}>Change Password</DropdownMenu.Item>
                        <DropdownMenu.Separator />
                        <DropdownMenu.Item onClick={handleLogOut} color="red">
                            Log Out
                        </DropdownMenu.Item>
                    </DropdownMenu.Content>
                </DropdownMenu.Root>

            </div>
            {modalVisible.visible && 
                <UserAccountModal 
                    user={globals.userData || {}}
                    isOpen={modalVisible.visible}
                    mode={modalVisible.mode}
                    onClose={()=>setModalVisibility({visible: false})}
                /> }
            {
                changePasswordVisible && 
                
                    <ChangePasswordModal 
                    isOpen={changePasswordVisible}
                    onClose={()=>setChangePasswordVisibility(false)}
                />
            }
        </div>
    )
}

export default CarsHead;