import React, { useState, useEffect, useContext } from "react";
import AddEditCommitted from "./AddEditCommitted";
import CommonThead from "./CommonThead";
import utilities from "./UtilityFunctions";
import { GlobalContext } from "./Cars";
//import { passesFilter } from "./UtilityFunctions";
import CommonTBody from "./CommonTBody";
import { Button } from "@radix-ui/themes";

function Committed({ cars, setCars }){
    const ownSheetName = "Credit";
    const globals = useContext(GlobalContext);
    const { socket } = globals;
    const [ modalVisible, setModalVisibility ] = useState({
        visible: false,
        mode: "add"
    });
    const [ toggleReload, setToggleReload ] = useState(true); //Force a reload after sorting

    const [ sortOrder, setSortOrder ] = useState(()=>{
        const sortObj = {};
        cars && cars[0] && Object.keys(cars[0]).forEach((propertyName)=>{
            sortObj[propertyName] = "none"; //Other values will be "asc" and "desc"
        });
        return sortObj;
    })

    const [ filterObject, setFilterObject ] = useState({});

    const sortCars = (field)=>{
        setSortOrder((prev)=>{
            setCars((prevCars)=>{
                return  utilities.sortArray(prevCars, field, prev[field]==="asc"?"desc":"asc")
            });
            return {...prev, [field]: prev[field]==="asc"?"desc":"asc"}
        })
        setToggleReload(prev=>!prev);
    }

    const filterData = (fieldName, filterType, lowerValue, upperValue)=>{
        console.log(fieldName, filterType, lowerValue, upperValue);
        if(!filterType) return {}; //This way, just call with only first argument to clear the filter
        setFilterObject(prev=>{
            return {...prev, [fieldName]: { filterType, lowerValue, upperValue }}
        });
    }

    return (
        <div className="page">
            <Content 
                modalVisible={modalVisible} 
                setModalVisibility={setModalVisibility} 
                cars={cars} setCars={setCars} sortCars={sortCars}  
                sortOrder={sortOrder} parentSheetName={ownSheetName} 
                filterData={filterData} filterObject={filterObject}
                key={cars.length}
            />
            <Summary setModalVisibility={setModalVisibility} cars={cars} />
        </div>
    )
}

function Content({ cars, setCars, modalVisible, setModalVisibility, sortCars, sortOrder, parentSheetName, filterData, filterObject }){
    const FieldOrder = useContext(GlobalContext).fieldOrder; 
        const [ fieldOrder, setFieldOrder ] = useState((()=>{

        const tmpFieldOrder = FieldOrder? FieldOrder[parentSheetName] : null;
            if(!tmpFieldOrder?.length){ //0 or undefined. Build one
                let ownFields = cars && cars[0] && Object.getOwnPropertyNames(cars[0]); //Fields of first row
                ownFields = ownFields?ownFields:[]; //If undefined, return an empty array
    
                const defaultFieldOrder = ownFields.map((strField)=>{
                    //Return an object {name:name,hidden:false}
                    return {name: strField, hidden: false};
                });
                
                return defaultFieldOrder;
            }
            return tmpFieldOrder;
        })()); //Get initial from globals
    const [ currentVehicleIndex, setCurrentVehicle ] = useState();
    const openModalEdit = (vehicleId)=>{
        setCurrentVehicle(vehicleId);
        setModalVisibility({
            visible: true,
            mode: "edit"
        });
    }

/*
    const updateCarsLocally = (newCar)=>{ //Only update the car which was updated in the modal
        setCars((prev)=>{
            prev.splice(currentVehicleIndex, 1, newCar);
            return prev;
        });
    }
*/
    const [ selectedRow, setSelectedRow ] = useState("")

    const selectRow = (rowIndex)=>{
        setSelectedRow(rowIndex)
    }

    const formattingRules = {
        Days: {
            formatByValue: (val)=>{
                if(val > 240 && val <= 270){
                    return {color: "orange", fontWeight: "bold"}
                }
                if(val > 270){
                    return {color: "red", fontWeight: "bold"}
                }
                return {color: "green", fontWeight: "bold"}
            }
        },
        DaysCommitted: {
            formatByValue: (val)=>{
                if(val > 30 && val <= 60){
                    return {color: "orange", fontWeight: "bold", textAlign: "center"}
                }
                if(val > 60){
                    return {color: "red", fontWeight: "bold", textAlign: "center"}
                }
                return {color: "green", fontWeight: "bold", textAlign: "center"}
            }
        }
    }

    return (
        <div className="content">
            <table className="table table-striped table-hover table-sm" >
                <CommonThead
                    objectsArray={cars}
                    sortObjectsArray={sortCars}
                    sortOrder={sortOrder}
                    fieldOrder={fieldOrder}
                    setFieldOrder={setFieldOrder}
                    parentSheetName={parentSheetName}
                    filterData={filterData}
                    filterObject={filterObject}
                />
                <CommonTBody
                    objectsArray={cars}
                    filterObject={filterObject}
                    selectRow={selectRow}
                    selectedRow={selectedRow}
                    fieldOrder={fieldOrder}
                    openModalEdit={openModalEdit}
                    clickableField={"ChassisNo"}
                    primaryKeyName={"VehicleID"}
                    formattingRules={formattingRules}
                />
            </table>
            {
                modalVisible.visible &&  cars[currentVehicleIndex] &&
                <AddEditCommitted 
                    formMode={modalVisible.mode} 
                    showModal={setModalVisibility} 
                    car={cars[currentVehicleIndex]} 
                    parentSheetName={parentSheetName} 
                    //In add mode, read the origin that the user selected. In edit mode, read the origin from the record's origin field
                    origin={modalVisible.mode==="add" ? modalVisible.origin : cars[currentVehicleIndex].Origin}
                />
            }

        </div>
    )
}

function Summary({ setModalVisibility, cars}){
    return (
        <div className="summary">
            <div className="aggregate">
                <div>Total Number:</div><div>{cars.length}</div>
                <div>Total Value:</div><div>{(()=>{
                    let value=0;
                    cars.forEach((car)=>{
                        value+=car.TotalCost
                    })
                    return utilities.formatIntoInput(value, "decimal");
                })()}</div>
            </div>
        </div>
    )
}

export default Committed;