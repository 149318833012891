import React, { useContext, useState } from "react";
import ReactDOM from 'react-dom';
import fields from "./fields";
import { GlobalContext } from './Cars';
function CommonThead({ objectsArray, sortObjectsArray, sortOrder, fieldOrder, setFieldOrder, parentSheetName, filterData, filterObject}){
    const [contextMenuPosition, setContextMenuPosition] = useState(null);
    const [activeColumn, setActiveColumn] = useState(null);
    const globals = useContext(GlobalContext);
    const { DealershipID, UserID, globalRef } = globals;
    const moveItem = (array, fromIndex, toIndex)=>{
        // Ensure indices are valid
        if (fromIndex < 0 || fromIndex >= array.length || toIndex < 0 || toIndex >= array.length) {
            throw new Error("Invalid index");
        }
        //console.log(`Before (From): ${array[fromIndex].name}: ${fromIndex}`);
        //console.log(`Before (To): ${array[toIndex].name}: ${toIndex}`);
        // Remove the item from the original position
        const [item] = array.splice(fromIndex, 1);
    
        // Insert the item at the new position
        array.splice(toIndex, 0, item);
        //console.log(`After (From): ${array[fromIndex].name}: ${fromIndex}`);
        //console.log(`After (To): ${array[toIndex].name}: ${toIndex}`);
        //console.table(array);
        return [...array];
    }
    const handleDragOverTd = (e) => {
        if(e.target.tagName!=="TH"){
            return;
        }
        e.preventDefault();
        const clientX = e.clientX;
        const target = e.target;
        const rect = target.getBoundingClientRect();
        const width = rect.width;
        const left = rect.left;
    
        // Determine the half being hovered
        const half = (clientX - left) <= width / 2 ? "First" : "Second";
    
        // Reset classes first
        target.classList.remove("drag-hover-left", "drag-hover-right");
        const elmts = document.getElementsByClassName(`col-${e.target.dataset.index}`);
        for(let elmt of elmts){
            elmt.classList.remove("drag-hover-left", "drag-hover-right");
        }
        // Add appropriate class
        if (half === "First") {
            target.classList.add("drag-hover-left");
            for(let elmt of elmts){
                elmt.classList.add("drag-hover-left");
            }
        } else {
            target.classList.add("drag-hover-right");
            for(let elmt of elmts){
                elmt.classList.add("drag-hover-right");
            }
        }
    };
    const handleDragLeaveTd = (e) => {
        const target = e.target;
        // Remove drag hover classes when the cursor leaves
        target.classList.remove("drag-hover-left", "drag-hover-right");
        const elmts = document.getElementsByClassName(`col-${e.target.dataset.index}`);
        for(let elmt of elmts){
            elmt.classList.remove("drag-hover-left", "drag-hover-right");
        }
    };
    const handleDrop = (e) => {
        const target = e.target;
        const clientX = e.clientX;
        const rect = target.getBoundingClientRect();
        const width = rect.width;
        const left = rect.left;
        // Ensure classes are removed on drop
        target.classList.remove("drag-hover-left", "drag-hover-right");
        const elmts = document.getElementsByClassName(`col-${e.target.dataset.index}`);
        for(let elmt of elmts){
            elmt.classList.remove("drag-hover-left", "drag-hover-right");
        }
        // Determine the half being hovered
        const half = (clientX - left) <= width / 2 ? "First" : "Second";
    
        const toIndex = half==="First" ? Number(e.target.dataset.index):Number(e.target.dataset.index)+1;
        const fromIndex = Number(e.dataTransfer.getData("text/plain"))
        
        if(!fromIndex || !toIndex) return; //Either is invalid
        if(fromIndex===toIndex) return; //Dropping on same element
        console.log(`From: ${fromIndex}, To: ${toIndex}`);
        setFieldOrder(prev=>{
            const newArr = moveItem(prev,fromIndex, toIndex);
            persistToDB(newArr); //Update the db and field order
            globalRef.current.fieldOrder[parentSheetName] = newArr;
            return newArr; 
        })
    };
    const handleDragStart = (e) => {
        // Add a class to indicate dragging
        e.target.classList.add("dragging");
        e.dataTransfer.setData("text/plain", e.target.dataset.index);
        console.log(e.target.dataset.index);
    };
    const handleDragEnd = (e) => {
        // Remove the dragging class
        e.target.classList.remove("dragging");
    };
    const handleContextMenu = (e, columnIndex) => {
        e.preventDefault();
        setContextMenuPosition({ x: e.pageX, y: e.pageY });
        setActiveColumn({
            columnIndex,
            columnDataType: fields[fieldOrder[columnIndex].name].dataType
        });
    };
    const hideContextMenu = () => {
        setContextMenuPosition(null);
        setActiveColumn(null);
    };

    const handleHideColumn = () => {
        console.log("Hide Column:", activeColumn.columnIndex);
        setFieldOrder(prev=>{ //Set order in parent component so the setting reaches TBody
            prev[activeColumn.columnIndex].hidden = true;
            const newArr = [...prev];
            persistToDB(newArr);
            return newArr;
        })
        //Also persist the setting globally

        hideContextMenu();
    };

    const handleUnhideColumns = () => {
        console.log("Unhide Columns");
        setFieldOrder(prev=>{
            
            for(let obj of prev){
                obj.hidden = false;
            }
            const newArr = [...prev];
            persistToDB(newArr);
            return newArr;
        })
        hideContextMenu();
    };

    const handleSort = ()=>{
        sortObjectsArray(fieldOrder[activeColumn.columnIndex].name);
        hideContextMenu();
    }

    const persistToDB = (fieldArr)=>{
        //{ DealershipID, UserID, ownSheetName, FieldOrder }
        fetch("https://api.autodealerug.com/edituserpreferences", {
            method: "Post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ 
                DealershipID: DealershipID, 
                UserID: UserID, 
                ownSheetName: parentSheetName, 
                FieldOrder: fieldArr 
            })
        }).then(async (res)=>{
            const result = await res.json();

            if(res.ok){
                if(result.ok){
                    console.log("Successfully queried");
                } else {
                    console.log(`Query issue: ${result.message}`);
                }
            } else {
                console.log(result.message);
            }
        }).catch((err)=>{
            console.log(err.message);
        });
    }

    return (
        <thead className="thead-inventory">
            <tr
            >
                {
                    objectsArray && objectsArray[0] && fieldOrder.map((fieldObj, fieldIndex,arr)=>{
                        if(fields[fieldObj.name].public && !fieldObj.hidden){
                            return (
                                <th 
                                    draggable
                                    onDragStart={handleDragStart}
                                    onDragOver={handleDragOverTd}
                                    onDragEnd={handleDragEnd}
                                    onDragLeave={handleDragLeaveTd}
                                    onDrop={handleDrop}
                                    onContextMenu={(e) => handleContextMenu(e, fieldIndex)}
                                    id={"inv-th"+fieldObj.name} 
                                    key={fieldIndex}
                                    data-index={fieldIndex}
                                    title="Drag to rearrange, right click for more"
                                    className={"caret caret-"+sortOrder[fieldObj.name]}
                                    //onClick={()=>sortObjectsArray(fieldObj.name)}
                                    >
                                        {fields[fieldObj.name].displayName+" "}
                                </th>
                            )
                        } else {
                            return null;
                        }
                    })

                }
                {
                    contextMenuPosition !== null && ReactDOM.createPortal(
                        <ContextMenu
                            fieldName={fieldOrder[activeColumn.columnIndex].name}
                            fieldType={activeColumn.columnDataType}
                            position={contextMenuPosition}
                            onHide={hideContextMenu}
                            onFilter={filterData}
                            filterObject={filterObject}
                            onSort={handleSort}
                            onHideColumn={handleHideColumn}
                            onUnhideColumns={handleUnhideColumns}
                        />, document.body)
                    }
            </tr>
        </thead>
    )
}

function ContextMenu({ fieldName, fieldType, position, onHide, onSort, filterObject, onFilter, onHideColumn, onUnhideColumns }) {
    // State to manage selected filter type and values
    const [filterType, setFilterType] = useState(null);
    const [filterValue1, setFilterValue1] = useState("");
    const [filterValue2, setFilterValue2] = useState("");
    
    if (!position) return null;

    const menuWidth = 250; // Width of the menu. Same is set in css
    const padding = 10; // Small padding to avoid touching the edge
    const viewportWidth = window.innerWidth;

    // Adjust left position if too close to the right edge
    let adjustedLeft = position.x;
    if (position.x + menuWidth > viewportWidth - padding) {
        adjustedLeft = viewportWidth - menuWidth - padding;
    }
    if (position.x < padding) {
        adjustedLeft = padding; // Prevents sticking to the very left edge
    }

    const style = {
        top: position.y - 50,
        left: adjustedLeft,
    };

    const fieldDataType = (fieldType === "integer" || fieldType === "decimal" || fieldType === "date") ? "numerical" : fieldType;

    // Handle filter submission
    const handleApplyFilter = () => {
        if (filterType) {
            onFilter(fieldName, filterType, filterValue1, filterValue2);
            onHide(); // Hide the menu after applying the filter
        }
    };

    const clearFilter = ()=>{
        onFilter(fieldName); //Only one argument to clear the filter
        onHide();
    }

    return (
        <div style={style} className="context-menu" onMouseLeave={onHide}>
            <div className="options">
                <div className="option-item" onClick={onSort}>Sort</div>

                <hr />

                <div className="option-item" onClick={() => setFilterType("=")}>{fieldType === "string" ? "Text contains" : "Equals"}</div>
                {fieldDataType === "numerical" && (
                    <>
                    <div className="option-item" onClick={() => setFilterType(">")}> {fieldType === "date" ? "Date is after" : "Greater Than"}</div>
                    <div className="option-item" onClick={() => setFilterType(">=")}> {fieldType === "date" ? "Date is on or after" : "Greater than or equal to"}</div>
                    <div className="option-item" onClick={() => setFilterType("<")}> {fieldType === "date" ? "Date is before" : "Less than"}</div>
                    <div className="option-item" onClick={() => setFilterType("<=")}> {fieldType === "date" ? "Date is on or before" : "Less than or equal to"}</div>
                    <div className="option-item" onClick={() => setFilterType("between")}> {fieldType === "date" ? "Date is between" : "Between"}</div>
                </>
                )}

                {filterType && (
                    <div className="filter-inputs">
                        <input
                            className="form-control"
                            type={(()=>{
                                console.log(fieldType);
                                switch(fieldType){
                                    case "string":
                                        return "text"
                                    case "integer":
                                        return "number";
                                    case "decimal":
                                        return "number";
                                    case "date":
                                        return "date"
                                }
                            })()}
                            value={filterValue1}
                            onChange={(e) => setFilterValue1(e.target.value)}
                            placeholder={fieldType === "date" ? "Select date" : "Enter value"}
                        />
                        {filterType === "between" && (
                            <input
                             className="form-control"
                                type={fieldType === "date" ? "date" : "number"}
                                value={filterValue2}
                                onChange={(e) => setFilterValue2(e.target.value)}
                                placeholder={fieldType === "date" ? "Select end date" : "Enter second value"}
                            />
                        )}
                        <button className="btn btn-secondary" onClick={handleApplyFilter}>Apply</button>
                    </div>
                )}

                { Object.getOwnPropertyNames(filterObject).length!==0 && <button className="btn btn-secondary" onClick={clearFilter}>Clear Filter</button>}
                
                <hr />
                
                <div className="option-item" onClick={onHideColumn}>Hide Column</div>
                <div className="option-item" onClick={onUnhideColumns}>Unhide Columns</div>
            
            </div>
        </div>
    );
}


export default CommonThead
