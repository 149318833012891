import React from "react";
import { passesFilter } from "./UtilityFunctions";
import fields from "./fields";
import utilities from "./UtilityFunctions";

function CommonTBody({ 
    objectsArray, 
    filterObject,
    selectRow, 
    selectedRow, 
    fieldOrder,
    openModalEdit,
    clickableField,
    primaryKeyName,
    formattingRules
    }){

    return(
        <tbody>
        {
            objectsArray && objectsArray.map((rowObj, rowindex, arr)=>{

                const shouldRenderRow = Object.keys(rowObj).every(key =>{
                    //If the key doesn't have a corresponding filter, return
                    if(!filterObject[key]) return true;

                    //console.log(`Key: ${key}, Value: ${rowObj[key]} filterObject: ${JSON.stringify(filterObject[key])}`);
                    return passesFilter(rowObj[key], filterObject[key], fields[key].dataType);
                    
                }
                );
            
                if (!shouldRenderRow) return null; // Skip this row

                return (<tr key={rowindex} onClick={()=>selectRow(rowObj[primaryKeyName])} className={rowObj[primaryKeyName]==selectedRow?"selected-row":""}>
                    {
                        fieldOrder.map((fieldObj, fieldindex,arrd)=>{ //arrd = array of data
                            //console.log(`${fieldObj.name}: ${fields[fieldObj.name].public}`);
                            if(fields[fieldObj.name].public && !fieldObj.hidden){ //If the field is public and not hidden. In addition, if it doesn't pass the filter, skip the entire row
                                if(fieldObj.name===clickableField){ //TODO instead of "ChassisNo", use whatever the head column is
                                    return(
                                        <td key={fieldindex} className={`col-${fieldindex}`}>
                                            <a href="#" onClick={(e)=>{
                                                e.preventDefault();
                                                openModalEdit(rowindex)
                                            }}>
                                            {utilities.formatIntoInput(rowObj[fieldObj.name], fields[fieldObj.name].dataType)}
                                            </a>
                                        </td>
                                    );
                                } else {
                                    let format = {}
                                    if(formattingRules && formattingRules[fieldObj.name]){
                                        format = formattingRules[fieldObj.name].formatByValue(rowObj[fieldObj.name]);
                                    }
                                    return(
                                        <td key={fieldindex} className={`col-${fieldindex}`} style={format}>
                                            {utilities.formatIntoInput(rowObj[fieldObj.name], fields[fieldObj.name].dataType)}
                                        </td>
                                    );
                                }
                            }
                        })
                    }
                </tr>)
            })
        }
        </tbody>
    )
}

export default CommonTBody;