import React, { useState, useEffect, useContext, useRef } from "react";
import fields from "./fields";
import { GlobalContext } from "./Cars";
import utilities from "./UtilityFunctions"

function AddEditBroker({ formMode="add", brokerData, showModal, parentSheetName="Brokers" }){
  const globals = useContext(GlobalContext);
  const { socket, toggleMessageBox } = globals;
  const [formData, setFormData] = useState(()=>{
    if(formMode==="add"){
      return { //DealershipID, FirstName, LastName, IDType, IDNumber, Bond, Phone1, Phone2
        FirstName: "",
        LastName: "",
        Bond: "",
        Phone1: "",
        Phone2: "",
      };
    } else {
      return brokerData;
    }
  });
  const [backupFormData, setBackupFormData] = useState(formData)
  const pendingMessages = useRef([]);
  const table_name = "Brokers";
  const primary_key = "BrokerID";
  const { sendOrQueue } = utilities;
  const fieldUpdateListener = (msg)=>{
    if(msg.sheetName!==parentSheetName){
      //Only handle events for this sheet
      return;
    }
    console.log(msg);
    //Get the Field and Status {ok: true/false, fieldName: fieldName}
    if(msg.ok){ //Update was successful
        //Update the fallback
        setBackupFormData({...backupFormData, [msg.fieldName]: formData[msg.fieldName]});
        
    } else { //Check and log msg.error
        //Show error and revert to fallback
        setFormData({...formData, [msg.fieldName]:backupFormData[msg.fieldName]});
    }
  }
  const ioReconnectListener = ()=>{
    console.log(`Socket connected? ${socket.connected}. socket.io reconnect event`);
    let attempts = 100;
    let currMsg = {};
    //Check the queue for any messages for transmission
    while(pendingMessages.current.length>0) {
      console.log(`${pendingMessages.current.length} messages in queue`);
        //Limit no. of attempts to 100 to prevent endless loop
        currMsg = pendingMessages.current.shift();
        sendOrQueue(currMsg, socket, pendingMessages.current, formData, backupFormData, setFormData);
        if(attempts===0){
            break;
        }
        attempts--;
      }
  }
  useEffect(()=>{
    //Add event listeners to the websocket
    //Return a function to remove them when component dismounts
    if(socket.disconnected){
      console.log("Socket disconnected")
        socket.connect()
    }
    
    socket.on('update_record', fieldUpdateListener);
    socket.on('connect',ioReconnectListener);

    return ()=>{
        socket.off('update_record',fieldUpdateListener);
        socket.off('connect', ioReconnectListener);
    }
  },[]);
  const [errors, setErrors] = useState({
    FirstName: "",
    LastName: "",
    Bond: "",
    Phone1: "",
    Phone2: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleBlur = ({ target })=>{
    //Do the same check as before submitting, except with only this input
    let err = "";
    const attr = target.name;
    let regex = "";
    if(fields[attr] && fields[attr].regex){ //If the regex exists
      regex = new RegExp(fields[attr].regex);
      if(!regex.test(formData[attr])){ //If the regex test fails
        err = "Invalid value for " + fields[attr].displayName;
      }  
    }
    if(fields[attr] && fields[attr].required && formData[attr]===""){
      err = fields[attr].displayName + " is required";
    }
    
    setErrors(prev=>{
      return {...prev, [attr]:err}
    })

    if(err){
      //If there are errors, show errors and return. Return is only really
      //useful in edit mode because it skips the submission code
      target.focus();
      return;
    } //In add mode, nothing need be done beyond this point

    if(formMode==="edit" && !(formData[target.name]===backupFormData[target.name])){ //In edit mode, update the db
      sendOrQueue({
        DealershipID: globals.DealershipID,
        UserID: globals.userData.UserID,
        ownSheetName: parentSheetName,
        table: table_name,
        fieldName: target.name,
        fieldValue: target.value,
        primaryKeyName: primary_key,
        primaryKeyValue: formData.BrokerID,
        triggerRefresh: fields[target.name].triggerRefresh
      }, socket,pendingMessages.current, formData, backupFormData, setFormData)
    } 
  }
  const validate = () => {
    let tempErrors = {};
    let regex;
    for(let attr of Object.keys(formData)){
      if(fields[attr] && fields[attr].regex){ //If the regex exists
        regex = new RegExp(fields[attr].regex);
        if(!regex.test(formData[attr])){ //If the regex test fails
          tempErrors[attr] = "Invalid value for " + fields[attr].displayName;
        }  
      }
      if(fields[attr] && fields[attr].required && formData[attr]===""){
        tempErrors[attr] = fields[attr].displayName + " is required";
      }
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      //Handle submission
      if(formMode==="add"){
        //Do the checks and submit, then close and refresh
        //Submit
        fetch("https://api.autodealerug.com/addbroker", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                ...formData,
                DealershipID: globals.DealershipID,
                ownSheetName: parentSheetName
            })
        })
        .then(async (res)=>{
          const resObj = await res.json();
            if(res.ok){
                //TODO: Refetch the brokers
                //Close the modal
                showModal({
                  visible: false,
                  formMode: ""
              })
              //Call the callback for refetching
            } else {
              console.log(resObj);
              toggleMessageBox({
                  on: true,
                  title: "Fetch Error",
                  message: resObj.message,
                  buttons: ["OK"],
                  clicked: ""
              })
            }
        }).catch((err)=>{
            toggleMessageBox({
                on: true,
                title: "Network Error",
                message: err.message,
                buttons: ["OK"],
                clicked: ""
            })
        })
      } else {
        showModal({
          visible: false,
          formMode: ""
      })
          //Call the callback to re-fetch brokers
          //And set the newly added as the selected one
      }
    }
  }; 
  const handleClose = ()=>{
    //TODO: Ask user for confirmation

    //Empty the objects
    showModal({
      visible: false,
      formMode: ""
  })
  };

//#####################  RETURN #######################################
  return (
    <>
      {
        <div className="customer-seller-form modal-overlay">
          <div className="modal-content">
            <button
              className="close-modal-btn"
              onClick={handleClose}
            >
              &times;
            </button>
            <form>
              <h2>{formMode==="add"?"Add":"Edit"} Broker</h2>

              <div className="grid-container">
                <div>
                  <label>First Name:</label>
                  <input
                    type="text"
                    name="FirstName"
                    value={formData.FirstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.FirstName && <span className="error">{errors.FirstName}</span>}
                </div>

                <div>
                  <label>Last Name:</label>
                  <input
                    type="text"
                    name="LastName"
                    value={formData.LastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.LastName && <span className="error">{errors.LastName}</span>}
                </div>

                <div>
                  <label>Bond:</label>
                  <input
                    type="text"
                    name="Bond"
                    value={formData.Bond}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.Bond && <span className="error">{errors.Bond}</span>}
                </div>

                <div>
                  <label>Phone 1:</label>
                  <input
                    type="text"
                    name="Phone1"
                    value={formData.Phone1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.Phone1 && <span className="error">{errors.Phone1}</span>}
                </div>

                <div>
                  <label>Phone 2:</label>
                  <input
                    type="text"
                    name="Phone2"
                    value={formData.Phone2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.Phone2 && <span className="error">{errors.Phone2}</span>}
                </div>

              </div>
              <div className="btn-container">
                <button type="submit" className="submit-btn" onClick={handleSubmit}>{formMode==="add"?"Register":"Close"}</button>
              </div>
            </form>
          </div>
        </div>
      }
    </>
  );
};

export default AddEditBroker;
