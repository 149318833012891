import React, { useState, useEffect, useContext, useRef } from "react";
import { GlobalContext } from "./Cars";
import { encrypt, decrypt } from "./UtilityFunctions";
import fields from "./fields";
import utilities from "./UtilityFunctions";
import UserAccountModal from "./AddEditUserAccount";
import { Button, Flex, Table } from "@radix-ui/themes";

function Settings(){
    const { socket, DealershipID, companyData, toggleMessageBox, logOut, UserID } = useContext(GlobalContext);
    const [ selectedMenuItem, setSelectedMenuItem ] = useState("Company")
    const [ backupCompanyDetails, setBackupCompanyData ] = useState(()=>{
        const newObj = {};
        for(let key in companyData){
            newObj[key] = companyData[key]===null? "" : companyData[key];
        }
        return newObj;
    })
    const [ companyDetails, setCompanyDetails ] = useState(backupCompanyDetails);
    const [ userAccounts, setUserAccounts ] = useState([]);
    const userAccountHeaders = ["UserName", "FirstName", "LastName", "Phone1", "Phone2" ];
    const [showPassword, setShowPassword] = useState(false);
    const pendingMessages = useRef([]);
    const table_name = "Dealerships"; //TODO
    const primary_key = "DealershipID";
    const ownSheetName = "Settings";
    const { sendOrQueue } = utilities;
    const [ showAccountEditModal, setShowAccountEditModal ] = useState({visible: false, mode: "edit", rowIndex: ""})
    const [ smsBalance, setSMSBalance ] = useState("");
    
    const fetchUserAccounts = async () => {
        try {
            const response = await fetch('https://api.autodealerug.com/getuseraccounts', {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({DealershipID: DealershipID})
            });
    
            let resObj = {};
    
            if (response.status === 200) {
                resObj = await response.json();
                //console.log(resObj);
                if (!Array.isArray(resObj.data)) {
                    console.error("Invalid response data format");
                    return;
                }
                //The response is an array of objects. Each object is a role with the
                //user ID, user name, first name, last name, Phone1, Phone2, role ID, role name and whether the role is granted
                //Reduce the data into unique UserInfo, with the roles condensed into an object
                const userAccountsArr = resObj.data.reduce((accumulator, curr) => {
                    const objIndex = accumulator.findIndex((obj) => obj.UserID === curr.UserID);
                    if (objIndex === -1) { // Not found in the accumulator, create one and push it to the cumulative array
                        const user = {
                            UserID: curr.UserID,
                            UserName: curr.UserName,
                            FirstName: curr.FirstName,
                            LastName: curr.LastName,
                            Phone1: curr.Phone1,
                            Phone2: curr.Phone2,
                            Permissions: {
                                Admin: false,
                                Sales: false,
                                Credit: false,
                                Inventory: false,
                            }
                        };
                        if (curr.RoleName) {
                            user.Permissions[curr.RoleName] = {RoleID: curr.RoleID, Granted: curr.Granted ? true : false};
                        }
                        accumulator.push(user);
                    } else {
                        if (curr.RoleName) {
                            accumulator[objIndex].Permissions[curr.RoleName] = {RoleID: curr.RoleID, Granted: curr.Granted ? true : false};
                        }
                    }
                    return accumulator;
                }, []);
    
                setUserAccounts(userAccountsArr);
            } else if (response.status === 500) {
                resObj = await response.json();
                toggleMessageBox({
                    on: true,
                    title: "Error",
                    buttons: ["OK"],
                    message: resObj.message
                });
            } else if (response.status === 401) {
                toggleMessageBox({
                    on: true,
                    title: "Unauthorized",
                    message: "Session Expired. Please login",
                    buttons: ["OK"]
                });
                logOut();
            } else {
                toggleMessageBox({
                    on: true,
                    title: "Unknown Error",
                    message: "An unknown error occurred. Please try again later. responseText = "+response.responseText,
                    buttons: ["OK"]
                });
            }
        } catch (err) {
            toggleMessageBox({
                on: true,
                title: "Network Error",
                message: "Please check your network and try again.",
                buttons: ["OK"]
            });
        }
    }

    const handleBlur = ({ target })=>{
        //If the value hasn't changed from the backup, ignore
        if(companyDetails[target.name]===backupCompanyDetails[target.name]) return;

        //Check for whether required fields are filled in
        if(fields[target.name] && fields[target.name].required && companyDetails[target.name]===""){
            toggleMessageBox({
                on: true,
                title: "Required Value",
                message: `${fields[target.name].displayName} can't be empty`,
                buttons: ["OK"]
            });
            return;
        }
        
        //Check for whether entered values are valid
        if(fields[target.name] && fields[target.name].regex){ //If the regex exists
            let regex = new RegExp(fields[target.name].regex);
            if(!regex.test(companyDetails[target.name])) {
                toggleMessageBox({
                    on: true,
                    title: "Invalid Value",
                    message: `Invalid value for ${fields[target.name].displayName}`,
                    buttons: ["OK"]
                })
                //Set focus to the input by it's id
                const focusElement = document.getElementById(target.name);
                focusElement.focus();
                return;
            }
        }

        //If it's _the_ password, encrypt it
        if(target.name === "MessagesPassword"){
            (async()=>{
                let encryptedPass = await encrypt(companyDetails[target.name], "No Kindazi For You");
                sendOrQueue({
                    DealershipID,
                    UserID,
                    ownSheetName: ownSheetName,
                    table: table_name,
                    fieldName: target.name,
                    fieldValue: encryptedPass, //Encrypted password
                    primaryKeyName: primary_key,
                    primaryKeyValue: DealershipID
                  }, socket,pendingMessages.current, companyDetails, backupCompanyDetails, setCompanyDetails)
            })()
            return;
        }

        //Submit a websocket request
        sendOrQueue({
            DealershipID,
            UserID,
            ownSheetName: ownSheetName,
            table: table_name,
            fieldName: target.name,
            fieldValue: target.value,
            primaryKeyName: primary_key,
            primaryKeyValue: DealershipID
          }, socket,pendingMessages.current, companyDetails, backupCompanyDetails, setCompanyDetails)

    }

    const handleChange = ({ target })=>{
        //TODO: 
        setCompanyDetails(prev=>{
            return {...prev, [target.name]: target.value}
        })
    }

    const fieldUpdateListener = (msg)=>{
        if(msg.sheetName==="Settings"){
            //Get the Field and Status {ok: true/false, fieldName: fieldName}
            if(msg.ok){ //Update was successful
                //Update the fallback
                setBackupCompanyData({...backupCompanyDetails, [msg.fieldName]: companyDetails[msg.fieldName]});
                
            } else { //Check and log msg.error
                //Show error and revert to fallback
                setCompanyDetails({...companyDetails, [msg.fieldName]:backupCompanyDetails[msg.fieldName]});
            }
        }
        if(msg.sheetName=="UserAccounts"){
            //Get the Field and Status {ok: true/false, fieldName: fieldName}
            if(msg.ok){ //Update was successful
                //Update the fallback
                setUserAccounts(prev=>{
                    return prev.map((user)=>{
                        if(user.UserID===msg.primaryKeyValue){
                            return {...user, [msg.fieldName]: msg.fieldValue}
                        } else {
                            return user;
                        }
                    })
                });
            }
        }

    }

    const ioReconnectListener = ()=>{
    console.log(`Socket connected? ${socket.connected}. socket.io reconnect event`);
    let attempts = 100;
    let currMsg = {};
    //Check the queue for any messages for transmission
    while(pendingMessages.current.length>0) {
        console.log(`${pendingMessages.current.length} messages in queue`);
        //Limit no. of attempts to 100 to prevent endless loop
        currMsg = pendingMessages.current.shift();
        sendOrQueue(currMsg, socket, pendingMessages.current, companyDetails, backupCompanyDetails, setBackupCompanyData);
        if(attempts===0){
            break;
        }
        attempts--;
        }
    }

    const getSMSBalance = async (decryptedSMSPassword) => {
        const smsUrl = `https://www.egosms.co/api/v1/json/`;
    
        try {
            const response = await fetch(smsUrl, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    method: "Balance",
                    "userdata":{
                        "username":companyDetails.MessagesAccount,
                        "password":decryptedSMSPassword
                    }
                })
            });
    
            if (!response.ok) throw new Error("Connection error");
    
            // Attempt to parse response as JSON
            let resObj;
            const contentType = response.headers.get("content-type");
    
            if (contentType && contentType.includes("application/json")) {
                resObj = await response.json();
            } else {
                resObj = { Balance: await response.text() }; // Handle plain text response
            }
    
            if (resObj.Status === "Failed") throw new Error(resObj.Message);
    
            // OK, update balance
            setSMSBalance(resObj.Balance.Balance);
    
        } catch (err) {
            toggleMessageBox({
                on: true,
                title: "Fetch error",
                message: "Unable to fetch SMS balance: " + err.message,
                buttons: ["OK"]
            });
        }
    };
    
    const addRecordListener = (msg)=>{
        console.log(msg);
        if(msg.sheetName!=="UserAccounts") return;

        const userAccountsArr = msg.newRecord.reduce((accumulator, curr) => {
            const objIndex = accumulator.findIndex((obj) => obj.UserID === curr.UserID);
            if (objIndex === -1) { // Not found in the accumulator, create one and push it to the cumulative array
                const user = {
                    UserID: curr.UserID,
                    UserName: curr.UserName,
                    FirstName: curr.FirstName,
                    LastName: curr.LastName,
                    Phone1: curr.Phone1,
                    Phone2: curr.Phone2,
                    Permissions: {
                        Admin: false,
                        Sales: false,
                        Credit: false,
                        Inventory: false,
                    }
                };
                if (curr.RoleName) {
                    user.Permissions[curr.RoleName] = {RoleID: curr.RoleID, Granted: curr.Granted ? true : false};
                }
                accumulator.push(user);
            } else {
                if (curr.RoleName) {
                    accumulator[objIndex].Permissions[curr.RoleName] = {RoleID: curr.RoleID, Granted: curr.Granted ? true : false};
                }
            }
            return accumulator;
        }, []);

        console.log(userAccountsArr);

        setUserAccounts(prev=>{
            return [...prev, userAccountsArr[0]];
        });
    }

    const deleteRecordListener = (msg)=>{
        console.log(msg);
        if(msg.sheetName!=="UserAccounts") return;
        const { primaryKeyName, primaryKeyValue } = msg;
        setUserAccounts(prev=>{
            const newArr = prev.filter((accountRecord)=>{
                return accountRecord[primaryKeyName] !== primaryKeyValue;
            });
            return newArr;
        });
    }

    useEffect(()=>{
        //Decrypt the password
        (async ()=>{
            const pass = await decrypt(companyData.MessagesPassword, "No Kindazi For You");
            
            setCompanyDetails(prev=>{
                return {...prev, MessagesPassword: pass};
            });
            setBackupCompanyData(prev=>{
                return {...prev, MessagesPassword: pass};
            });
        })()
        fetchUserAccounts();

        if(socket.disconnected){
            console.log("Socket disconnected")
              socket.connect()
          }
          
        socket.on('update_record', fieldUpdateListener);
        socket.on('connect',ioReconnectListener);
        socket.on('add_record', addRecordListener);
        socket.on('delete_record', deleteRecordListener);
    
        return ()=>{
            socket.off('update_record',fieldUpdateListener);
            socket.off('connect', ioReconnectListener);
            socket.off('add_record', addRecordListener);
            socket.off('delete_record', deleteRecordListener);
        }
    },[]);

    const handleAddNewAccount = ()=>{
        setShowAccountEditModal(prev=>{
            return {...prev, visible: true, mode: "add"}
        }
        )
    }
    return (
        <div id="settings">
            <div id="settings-menu">
                <button className={`menu-item ${selectedMenuItem==="Company" && " selected"}`} onClick={()=>{setSelectedMenuItem("Company")}}>Company info</button>
                <button className={`menu-item ${selectedMenuItem==="Accounts" && " selected"}`} onClick={()=>{setSelectedMenuItem("Accounts")}}>User Accounts</button>
                <button className={`menu-item ${selectedMenuItem==="TextMessaging" && " selected"}`} onClick={()=>{setSelectedMenuItem("TextMessaging")}}>SMS Account</button>
            </div>
            <div id="settings-content">
                {
                    selectedMenuItem==="Company" && 
                    <div className="content-pane">
                        <div className="input-group">
                            <label htmlFor="CompanyName">Company Name</label>
                            <input value={companyDetails.CompanyName} id="CompanyName" name="CompanyName" onBlur={handleBlur} onChange={handleChange} className="input" type="text"></input>
                        </div>

                        <div className="input-group">
                            <label htmlFor="ContactPhone1">Contact Phone</label>
                            <input value={companyDetails.ContactPhone1} id="ContactPhone1" name="ContactPhone1" onBlur={handleBlur} onChange={handleChange} className="input" type="text"></input>
                        </div>

                        <div className="input-group">
                            <label htmlFor="ContactPhone2">Contact Phone 2</label>
                            <input value={companyDetails.ContactPhone2} id="ContactPhone2" name="ContactPhone2" onBlur={handleBlur} onChange={handleChange} className="input" type="text"></input>
                        </div>

                        <div className="input-group">
                            <label htmlFor="BondName">Bond Name</label>
                            <input value={companyDetails.BondName} id="BondName" name="BondName" onBlur={handleBlur} onChange={handleChange} className="input" type="text"></input>
                        </div>

                    </div>
                }

                {
                    selectedMenuItem==="Accounts" && 
                    <div className="content-pane">
                        <Table.Root size="3" variant="surface" layout="auto">
                            <Table.Header>
                                <Table.Row>
                                {userAccountHeaders.map((header) => (
                                    <Table.ColumnHeaderCell key={header}>{header}</Table.ColumnHeaderCell>
                                ))}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {userAccounts.map((accObj, rowIndex) => (
                                <Table.Row key={rowIndex}>
                                    {userAccountHeaders.map((header, colIndex) => {
                                    if (header === 'UserName') {
                                        return (
                                        <Table.Cell key={`row-${rowIndex}-col-${colIndex}`}>
                                            <a
                                            href="#"
                                            onClick={() =>
                                                setShowAccountEditModal((prev) => ({
                                                ...prev,
                                                visible: true,
                                                rowIndex: rowIndex,
                                                mode: 'edit',
                                                }))
                                            }
                                            >
                                            {accObj[header]}
                                            </a>
                                        </Table.Cell>
                                        );
                                    } else {
                                        return (
                                        <Table.Cell key={`row-${rowIndex}-col-${colIndex}`}>
                                            {accObj[header]}
                                        </Table.Cell>
                                        );
                                    }
                                    })}
                                </Table.Row>
                                ))}
                            </Table.Body>
                        </Table.Root>
                        <div className="button-container">
                            <Button variant="soft" radius="large" color="green" onClick={handleAddNewAccount}>Add User</Button>
                        </div>
                    </div>
                }

                {
                    selectedMenuItem==="TextMessaging" && 
                    <div className="content-pane">

                        <div className="input-group">
                            <p>Account Balance (UGX): <button onClick={()=>getSMSBalance(companyDetails.MessagesPassword)} style={{border: "none", background: "none", color: "blue", textDecoration: "underline", cursor: "pointer"}}> Refresh </button> <b>{smsBalance}</b></p>
                        </div>

                        <div className="input-group">
                            <label htmlFor="MessagesAccount">Messages Account</label>
                            <input value={companyDetails.MessagesAccount} id="MessagesAccount" name="MessagesAccount" onBlur={handleBlur} onChange={handleChange} className="input" type="text"></input>
                        </div>

                        <div className="input-group">
                            <label htmlFor="MessagesPassword">Messages Password</label>
                            <input value={companyDetails.MessagesPassword} id="MessagesPassword" name="MessagesPassword" onBlur={handleBlur} onChange={handleChange} className="input" type={showPassword?"text":"password"}></input>
                        </div>
                        <div className='input-group checkbox'>
                            <label htmlFor="showPassword">Show Password</label>
                            <input type="checkbox" id="showPassword" checked={showPassword} onChange={() => setShowPassword(!showPassword)} />
                        </div>
                    </div>
                }

            </div>
            {
                (showAccountEditModal.mode==="add" || (showAccountEditModal.mode==="edit" && userAccounts[showAccountEditModal.rowIndex]))
                && showAccountEditModal.visible && 
                    <UserAccountModal
                        user={userAccounts[showAccountEditModal.rowIndex]} //TODO. Specify account to show
                        isOpen={showAccountEditModal.visible} 
                        onClose={()=>setShowAccountEditModal({visible: false})} 
                        mode={showAccountEditModal.mode}
                    />
            }
        </div>
    )
}

export default Settings;

