import React from "react";
import ReactDOM from "react-dom";
import { Theme } from "@radix-ui/themes";

const MessageBox = ({ 
    title, 
    message, 
    buttons = ["OK"], 
    onClick 
}) => {
    const handleClick = (button) => {
        if (onClick) onClick(button);
    };

    return ReactDOM.createPortal(
        <Theme>
        <div className="message-box-overlay">
            <div className="message-box">
                {title && <h3 className="message-box-title">{title}</h3>}
                <p className="message-box-message">{message}</p>
                <div className="message-box-buttons">
                    {buttons.map((button) => (
                        <button 
                            key={button} 
                            onClick={() => handleClick(button)}
                            className={`message-box-button ${button.toLowerCase()}`}
                        >
                            {button}
                        </button>
                    ))}
                </div>
            </div>
        </div>
        </Theme>,
        document.body
    );
};

export default MessageBox;
