import React, { useState, useEffect, useContext } from 'react';
import { io } from 'socket.io-client';
import { generateBase64String } from './UtilityFunctions';
import fields from './fields';
import { GlobalContext } from "./Cars";


function RegistrationForm({ setLogInStatus }) {
  const { toggleMessageBox } = useContext(GlobalContext);
  const [ socket, setSocket ] = useState(io('wss://api.autodealerug.com',
    {
        autoConnect: true,       // Ensures the socket connects automatically
        reconnection: true,      // Allows reconnection attempts
        //reconnectionAttempts: 5, // Number of attempts before giving up
        reconnectionDelay: 2000,          // Wait 2 seconds before the first attempt
        //reconnectionDelayMax: 10000,      // Delay grows up to 10 seconds
        auth: {
          DealershipID: "Registration"
        }
      }
  ))
  const [currentTab, setCurrentTab] = useState(0);
  const [companyInfo, setCompanyInfo] = useState({
    DealershipName: '',
    CompanyPhone1: '',
    CompanyPhone2: '',
    CompanyAddress: '',
  });
  const [personalInfo, setPersonalInfo] = useState({
    FirstName: '',
    LastName: '',
    Phone1: '',
    Phone2: '',
  });
  const [loginInfo, setLoginInfo] = useState({
    UserName: '',
    Password: '',
    ConfirmPassword: '',
  });
  const [errors, setErrors] = useState(companyInfo); 
  const [ isUnique, setIsUnique ] = useState({
    DealershipName: '',
    UserName: '',
  });
  const DealershipID = generateBase64String();

  const validatePassword = ()=>{
    if(!loginInfo.Password){ //Check that it's not empty
      setErrors(prev=>{
        return {...prev, Password: "Please enter a password"}
      })
      return false;
    } else {
      setErrors(prev=>{
        return {...prev, Password: ""}
      })
    }
    //Check length
    if(loginInfo.Password.length<6){
      setErrors(prev=>{
        return {...prev, Password: "Password is too short"}
      })
      return false;
    } else {
      setErrors(prev=>{
        return {...prev, Password: ""}
      })
    }
    //Compare password and confirmation
    if(loginInfo.Password!==loginInfo.ConfirmPassword){
      setErrors(prev=>{
        return {...prev, ConfirmPassword: "Passwords do not match"}
      });
      return false;
    } else {
      setErrors(prev=>{
        return {...prev, ConfirmPassword: ""}
      });
    }
    return true;
  }

  const validateForm = (info) => {
    let tempErrors = {};
    let hasErrors = false;
    let regex;
    for(let attr of Object.keys(info)){
      //Check for whether entered values are valid
      if(fields[attr] && fields[attr].regex){ //If the regex exists
        regex = new RegExp(fields[attr].regex);
        if(!regex.test(info[attr])){ //If the regex test fails
          tempErrors[attr] = "Invalid value for " + fields[attr].displayName;
          hasErrors = true;
        } else {
          tempErrors[attr] = ''; //Reset errors for only this field
        }
      }

      //Check for whether required fields are filled in
      if(fields[attr] && fields[attr].required && info[attr]===""){
        tempErrors[attr] = fields[attr].displayName + " is required";
        hasErrors = true;
      } else {
        if(!tempErrors[attr]) tempErrors[attr] = ''; //Reset errors for this field
      }
    }

    setErrors(prev=>{
      return {...prev, ...tempErrors}
    });
    return !hasErrors;
  };

  const handleBlur = ({ target })=>{
    switch(target.name){
      case "DealershipName":
        socket.emit('check-dealership-name', {DealershipName: companyInfo.DealershipName});
        setIsUnique(prev=>{
          return {...prev, DealershipName: 'Pending'}
        });
        break;
      case "UserName":
        socket.emit('check-username', {UserName: loginInfo.UserName});
        setIsUnique(prev=>{
          return {...prev, UserName: 'Pending'}
        });
        break;
      default:
    }

    currentTab===0 && validateForm(companyInfo);
    currentTab===1 && validateForm(personalInfo);
    currentTab===2 && validateForm(loginInfo);
  }

  const nextPrev = (n) => {
    //setErrors({}); //Reset the errors before checking again
    //console.log(`Errors: ${JSON.stringify(errors)}`);

    if(currentTab===0){
      if(!validateForm(companyInfo)) return;
      if(isUnique.DealershipName==='Pending') return;
      if(!isUnique.DealershipName) return;
    }
    if(currentTab===1 && !validateForm(personalInfo)) return;
    if(currentTab===2){
      if(!validateForm(loginInfo)) return;
      if(isUnique.UserName==='Pending') return;
      if(!isUnique.UserName) return;
      if(!validatePassword()) return;
    }

    const newTab = currentTab + n;
    if (newTab >= 3) {
      //Validate passwords
      if(!validatePassword()) return;
      //Submit the form
      fetch('https://api.autodealerug.com/newdealership',{
        method: "POST",
        headers: {
          "Content-Type": "Application/json"
        },
        credentials: "include",
        body: JSON.stringify({
          DealershipID: DealershipID,
          FirstName: personalInfo.FirstName,
          LastName: personalInfo.LastName,
          Phone1: personalInfo.Phone1,
          Phone2: personalInfo.Phone2,
          Username: loginInfo.UserName,
          UserPassword: loginInfo.Password, //Send unhashed to hash backend
          Dealership: {
              DealershipID: DealershipID,
              CompanyName: companyInfo.DealershipName,
              ContactPhone1: companyInfo.CompanyPhone1,
              ContactPhone2: companyInfo.CompanyPhone2,
              BondName: companyInfo.CompanyAddress
          }
        })
      }).then(async (response)=>{
        const resObj = await response.json();
        console.log(resObj);

        if(response.ok){ //Execution successful. Check results
          if(resObj.ok){ //Successfully added
            toggleMessageBox({
              on: true,
              title: "Dealership Created.",
              message: "Dealership successfully created. Please Login.",
              buttons: ["OK"],
                clicked: ""
            })
            setLogInStatus("login");
            console.log(`Dealership created`);
          } else { //Error with procedure OR sent data
            toggleMessageBox({
              on: true,
              title: "Error",
              message: "Error creating dealership. Please check your input data and try again.",
              buttons: ["OK"],
                clicked: ""
            })
            console.log(`Error: ${resObj.message}`);
          }
        } else { //Server error
          toggleMessageBox({
            on: true,
            title: "Error",
            message: "Error creating dealership. Please contact support.",
            buttons: ["OK"],
              clicked: ""
          })
          console.log(`Server error: ${resObj.message}`);
        }
      }).catch((err)=>{ //Network error?
        toggleMessageBox({
          on: true,
          title: "Connection Error",
          message: "Error creating dealership. Please check your network connection and try again.",
          buttons: ["OK"],
            clicked: ""
        })
        console.log(`Connection error: ${err.message}`);
      });
    } else {
      //Proceed to new tab
      setCurrentTab(newTab);
    }
  };


  const handleInputChange = (e) => {
    if(currentTab===0){
      setCompanyInfo({
        ...companyInfo,
        [e.target.name]: e.target.value
      });
    } else if(currentTab===1){
      setPersonalInfo({
        ...personalInfo,
        [e.target.name]: e.target.value
      });
    } else {
      setLoginInfo({
        ...loginInfo,
        [e.target.name]: e.target.value
      });
    }
  };

  useEffect(()=>{
    socket.on('dealership-name-exists', ()=>{
      setIsUnique(prev=>{
        return {...prev, DealershipName: false}
      });
    })
    socket.on('dealership-name-new', ()=>{
      setIsUnique(prev=>{
        return {...prev, DealershipName: true}
      });
    })

    socket.on('check-dealership-name-error', (msg)=>{ //Debug purposes only
      console.log(msg);
    });

    socket.on('username-exists', ()=>{
      setIsUnique(prev=>{
        return {...prev, UserName: false}
      });
    })

    socket.on('username-new', ()=>{
      setIsUnique(prev=>{
        return {...prev, UserName: true}
      });
    })

    socket.on('check-username-error', (msg)=>{ //Debug purposes only
      console.log(msg);
    });

    return ()=>{
      socket.removeAllListeners();
      socket.disconnect();
    }
  },[])

  return (
    <div className="register">
      <div className='header'>
        <div></div>
        <h1>Register</h1>
        <div className='login-link-container'>
          <a href='#' onClick={()=>{setLogInStatus("login")}}>
            LogIn
          </a>
        </div>
      </div>
      <form id="regForm">
        {/* Tabs */}
        {currentTab === 0 && (
          <div className="tab">
            <h5>Company Info</h5>
            <div>
              <input className='input' id="DealershipName" name="DealershipName" value={companyInfo.DealershipName} onChange={handleInputChange} onBlur={handleBlur} placeholder="Company Name..." />
              {errors['DealershipName'] && <p className='input-errors'>{errors['DealershipName']}</p>}
              {isUnique.DealershipName===false && <p className='input-errors'>This name is already registered. Please use a different one</p>}
              {isUnique.DealershipName==='Pending' && <p className='input-errors'>Please wait while the Company Name is verified</p>}
            </div>
            <div>
              <input className='input' name="CompanyAddress" value={companyInfo.CompanyAddress} onChange={handleInputChange} onBlur={handleBlur} placeholder="Address / Bond..." />
              {errors['CompanyAddress'] && <p className='input-errors'>{errors['CompanyAddress']}</p>}
            </div>
            <div>
              <input className='input' name="CompanyPhone1" value={companyInfo.CompanyPhone1} onChange={handleInputChange} onBlur={handleBlur} placeholder="Contact Phone 1..." />
              {errors['CompanyPhone1'] && <p className='input-errors'>{errors['CompanyPhone1']}</p>}
            </div>
            <div>
              <input className='input' name="CompanyPhone2" value={companyInfo.CompanyPhone2} onChange={handleInputChange} onBlur={handleBlur} placeholder="Contact Phone 2..." />
              {errors['CompanyPhone2'] && <p className='input-errors'>{errors['CompanyPhone2']}</p>}
            </div>
          </div>
        )}

        {currentTab === 1 && (
          <div className="tab">
            <h5>User Info</h5>
            <div>
              <input className='input' name="FirstName" value={personalInfo.FirstName} onChange={handleInputChange} onBlur={handleBlur} placeholder="First name..." />
              {errors['FirstName'] && <p className='input-errors'>{errors['FirstName']}</p>}
            </div>
            <div>
              <input className='input' name="LastName" value={personalInfo.LastName} onChange={handleInputChange} onBlur={handleBlur} placeholder="Last name..." />
              {errors['LastName'] && <p className='input-errors'>{errors['LastName']}</p>}
            </div>
            <div>
              <input className='input' name="Phone1" value={personalInfo.Phone1} onChange={handleInputChange} onBlur={handleBlur} placeholder="Phone1..." />
              {errors['Phone1'] && <p className='input-errors'>{errors['Phone1']}</p>}
            </div>
            <div>
              <input className='input' name="Phone2" value={personalInfo.Phone2} onChange={handleInputChange} onBlur={handleBlur} placeholder="Phone2..." />
              {errors['Phone2'] && <p className='input-errors'>{errors['Phone2']}</p>}
            </div>
          </div>
        )}

        {currentTab === 2 && (
          <div className="tab">
            <h5>Login Info</h5>
            <div>
              <input className='input' name="UserName" value={loginInfo.UserName} onChange={handleInputChange} onBlur={handleBlur} placeholder="UserName..." />
              {errors['UserName'] && <p className='input-errors'>{errors['UserName']}</p>}
              {isUnique.UserName===false && <p className='input-errors'>This username is taken. Please choose a different one.</p>}
              {isUnique.UserName==='Pending' && <p className='input-errors'>Please wait while the Username is verified</p>}
            </div>
            <div>
              <input className='input' name="Password" value={loginInfo.Password} onChange={handleInputChange} onBlur={()=>validatePassword()} type="Password" placeholder="Password..." />
              {errors['Password'] && <p className='input-errors'>{errors['Password']}</p>}
            </div>
            <div>
              <input className='input' name="ConfirmPassword" value={loginInfo.ConfirmPassword} onChange={handleInputChange} onBlur={()=>validatePassword()} type="Password" placeholder="Confirm Password..." />
              {errors['ConfirmPassword'] && <p className='input-errors'>{errors['ConfirmPassword']}</p>}
            </div>
          </div>
        )}

        {/* Navigation Buttons */}
        <div style={{ overflow: 'auto' }}>
          <div className='buttons-container'>
            <button className='btn' type="button" onClick={() => nextPrev(-1)} disabled={currentTab === 0}>
              Previous
            </button>
            <button className='btn' type="button" onClick={() => nextPrev(1)}>
              {currentTab === 2 ? 'Submit' : 'Next'}
            </button>
          </div>
        </div>

        {/* Steps Indicator */}
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          {[...Array(3)].map((_, index) => (
            <span
              key={index}
              className={`step ${currentTab === index ? 'active' : ''}`}
              style={{
                backgroundColor: currentTab === index ? '#04AA6D' : '#bbbbbb',
                opacity: currentTab === index ? 1 : 0.5
              }}
            />
          ))}
        </div>
      </form>
    </div>

  );
}

export default RegistrationForm;
