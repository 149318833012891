import React, { useState, useRef, useContext, useEffect } from "react";
import { GlobalContext } from "./Cars";
import utilities, { generatePassword } from "./UtilityFunctions"
import fields from "./fields";
import { Theme, Button, Flex } from "@radix-ui/themes";
import ReactDOM from "react-dom";

const defaultProfilePic = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

const UserAccountModal = ({ user, isOpen, onClose, mode }) => {
  if ( mode==="edit" && (!isOpen || !user?.UserID)){
    //After deleting the user, the user object is null
    console.log("CLosing");
    onClose();
  }
  //Mode is either "add" or "edit"
  const parentSheetName = "UserAccounts";
  //const ownSheetName = "Users";
  const table_name = "Users";
  const primary_key = "UserID";
  const { sendOrQueue } = utilities;
  const globals = useContext(GlobalContext);
  const { UserID, socket, toggleMessageBox, getMsgBoxResult, logOut } = globals;

  const [backupUserData, setBackupUserData] = useState(()=>{
    if(mode==="add"){
      return {
        UserName: "",
        FirstName: "",
        LastName: "",
        Phone1: "",
        Phone2: "",
      }
    } else {
      const returnObj = {};
      Object.getOwnPropertyNames(user).forEach((prop)=>{
        //Skip the Permissions object. It'll be handled in its own
        //separate state and appended to the user object when submitting
        if(prop==="Permissions") return;

        if(user[prop]!==null){
          returnObj[prop] = user[prop];
        } else {
          returnObj[prop] = "";
        }
      });
      return returnObj;
    }
  });

  const [ isUnique, setIsUnique ] = useState(()=>{
    return {UserName: ''}
  });
  const [ userData, setUserData ] = useState(backupUserData);
  const pendingMessages = useRef([]);

  const [backupRoles, setBackupRoles] = useState((()=>{
    if(mode==="add"){
      return {
        Admin: {RoleID: 2, Granted: false},
        Sales: {RoleID: 3, Granted: false},
        Credit: {RoleID: 4, Granted: false},
        Inventory: {RoleID: 5, Granted: true},
      } 
    } else {
      return {...user.Permissions};
    }
  }
  )());
  const [ roles, setRoles ] = useState(backupRoles);
  const [profilePic, setProfilePic] = useState(user?.profilePic || defaultProfilePic);
  const fileInputRef = useRef(null); // Create a ref for the file input
  const [errors, setErrors] = useState({
    UserName: "",
    FirstName: "",
    LastName: "",
    Phone1: "",
    Phone2: "",
  });

  const handleChange = ({ target }) => { //Set the state as user types
    //In edit mode, prevent changes to fields in userData if UserID !== UserData.UserID
    //If target.name is a property in userData, return
    if(mode==="edit" && UserID !==userData.UserID && target.name in userData){
      return;   
    }
    
    const { name, value } = target;
    setUserData({ ...userData, [name]: value });
  };

  const handleRoleChange = (role) => {
    //For roles, this may also have to handle submitting
    //the data to the db
    setRoles((prev) => {
      const newRole = {...prev[role]};
      newRole.Granted = !newRole.Granted;
      return {...prev, [role]: newRole};
    });
  };

  const handleProfilePicChange = (e) => { //TODO. May not be essential
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => setProfilePic(event.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleProfilePicClick = () => { //TODO. May not be essential
    fileInputRef.current.click(); // Trigger the file input click
  };

  const fieldUpdateListener = (msg)=>{
    if(msg.sheetName!==parentSheetName){
      //Only handle events for this sheet
      return;
    }
    //Get the Field and Status {ok: true/false, fieldName: fieldName}
    if(msg.ok){ //Update was successful
        //Update the fallback
        setBackupUserData({...backupUserData, [msg.fieldName]: userData[msg.fieldName]});
        
    } else { //Check and log msg.error
        //Show error and revert to fallback
        setUserData({...userData, [msg.fieldName]:backupUserData[msg.fieldName]});
    }
  }

  const ioReconnectListener = ()=>{
    console.log(`Socket connected? ${socket.connected}. socket.io reconnect event`);
    let attempts = 100;
    let currMsg = {};
    //Check the queue for any messages for transmission
    while(pendingMessages.current.length>0) {
      console.log(`${pendingMessages.current.length} messages in queue`);
        //Limit no. of attempts to 100 to prevent endless loop
        currMsg = pendingMessages.current.shift();
        sendOrQueue(currMsg, socket, pendingMessages.current, userData, backupUserData, setUserData);
        if(attempts===0){
            break;
        }
        attempts--;
      }
  }

  useEffect(()=>{
    //Add event listeners to the websocket
    //Return a function to remove them when component dismounts
    if(socket.disconnected){
      console.log("Socket disconnected")
        socket.connect()
    }
    
    socket.on('update_record', fieldUpdateListener);
    socket.on('connect',ioReconnectListener);
    socket.on('username-exists', ()=>{
      setIsUnique(prev=>{
        return {...prev, UserName: false}
      });
    })

    socket.on('username-new', ()=>{
      setIsUnique(prev=>{
        return {...prev, UserName: true}
      });
    })
  
    return ()=>{
        socket.off('username-exists');
        socket.off('username-new');
        socket.off('update_record',fieldUpdateListener);
        socket.off('connect', ioReconnectListener);
    }
  },[]);

  const handleBlur = ({ target })=>{
    //Ignore changes to the UserName field in edit mode
    if(target.name==="UserName"){
      if(mode==="edit") return;
      socket.emit('check-username', {UserName: userData.UserName});
      setIsUnique(prev=>{
        return {...prev, UserName: 'Pending'}
      });
    };

    //Do the same check as before submitting, except with only this input
    let err = ""; //Error message holder
    const attr = target.name;
    let regex = "";
    if(fields[attr] && fields[attr].regex){ //If the regex exists
      regex = new RegExp(fields[attr].regex); //Create the regex
      if(!regex.test(userData[attr])){ //Test against the entered data. If the regex test fails
        err = "Invalid value for " + fields[attr].displayName; //store the err for later display to the user
      }  
    }
    if(fields[attr] && fields[attr].required && userData[attr]===""){ //If the field is required and empty
      err = fields[attr].displayName + " is required"; //Set err = "Field is required"
    }
    
    setErrors(prev=>{ //Update the errors state so that the error message is displayed
      return {...prev, [attr]:err}
    })

    if(err){
      //If there are errors, show errors and return. Return is only really
      //useful in edit mode because it skips the submission code
      target.focus();
      return;
    } //In add mode, nothing need be done beyond this point

    if(mode==="edit" && !(userData[target.name]===backupUserData[target.name])){ //In edit mode, update the db if the value has changed
      sendOrQueue({
        DealershipID: globals.DealershipID,
        UserID: globals.userData.UserID,
        ownSheetName: parentSheetName,
        table: table_name,
        fieldName: target.name,
        fieldValue: target.value,
        primaryKeyName: primary_key,
        primaryKeyValue: userData.UserID
      }, socket,pendingMessages.current, userData, backupUserData, setUserData)
    } 
  }

  const validate = () => {
    let tempErrors = {};
    let regex;
    for(let attr of Object.keys(userData)){
      if(fields[attr] && fields[attr].regex){ //If the regex exists
        regex = new RegExp(fields[attr].regex);
        if(!regex.test(userData[attr])){ //If the regex test fails
          tempErrors[attr] = "Invalid value for " + fields[attr].displayName;
        }  
      }
      if(fields[attr] && fields[attr].required && userData[attr]===""){ //If the field is required and empty
        tempErrors[attr] = fields[attr].displayName + " is required";
      }
    }

    //Check that at least 1 of the user roles is selected
    if(Object.values(roles).filter((role)=>role.Granted).length===0){
      tempErrors.Roles = "At least one role must be selected";
    }

    //Check that the username is unique
    if(!isUnique.UserName) tempErrors.UserName = "Username already in use";
    if(isUnique.UserName==="Pending") tempErrors.UserName = "Please wait while we check the username";
    
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0; //Return true if there are no errors
  };

  /*const validatePassword = ()=>{
    if(!userData.UserPassword){ //Check that it's not empty
      setErrors(prev=>{
        return {...prev, UserPassword: "Please enter a password"}
      })
      return false;
    } else {
      setErrors(prev=>{
        return {...prev, UserPassword: ""}
      })
    }
    //Check length
    if(userData.UserPassword.length<6){
      setErrors(prev=>{
        return {...prev, UserPassword: "UserPassword is too short"}
      })
      return false;
    } else {
      setErrors(prev=>{
        return {...prev, UserPassword: ""}
      })
    }
    //Compare password and confirmation
    if(userData.UserPassword!==userData.ConfirmPassword){
      setErrors(prev=>{
        return {...prev, ConfirmPassword: "Passwords do not match"}
      });
      return false;
    } else {
      setErrors(prev=>{
        return {...prev, ConfirmPassword: ""}
      });
    }
    return true;
  }*/

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) {
      console.log("Validation failed: ", errors);
      return;
    }

    //Handle submission
    if(mode==="add"){ //Not a necessary check, but i'll leave it
      //Generate a temporary password for the user and add it to userData
      //They'll change it after they login the first time
      const tempPassword = generatePassword();
      userData.UserPassword = tempPassword; //Set the password. Not using setUserData because it's asynchronous

      //Submit
      fetch("https://api.autodealerug.com/adduseraccount", {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
              ...userData,
              ...(()=>{
                let temp = {};
                for(let role of Object.keys(roles)){
                  temp[role] = roles[role].Granted ? -1 : 0;
                }
                return temp;
              })(),
              DealershipID: globals.DealershipID,
              ownSheetName: parentSheetName
          })
      })
      .then(async (res)=>{
        const resObj = await res.json();
          if(res.ok){
              //TODO: Refetch the customers
              //Close the modal
              onClose();
            //Call the callback for refetching
          } else {
            console.log(resObj);
            toggleMessageBox({
                on: true,
                title: "Fetch Error",
                message: resObj.message,
                buttons: ["OK"],
                clicked: ""
            })
          }
      }).catch((err)=>{
          toggleMessageBox({
              on: true,
              title: "Network Error",
              message: err.message,
              buttons: ["OK"],
              clicked: ""
          })
      })
    } else {
      onClose();
        //Call the callback to re-fetch customers
        //And set the newly added as the selected one
    }
  
  }; 

  const handleDelete = async (uid)=>{
    //Check if the user is the currently logged in user
    //Not really necessary, but just in case the user is a smartass tech savvy
    //That tries to bypass the hidden delete button
    if(uid===UserID){
      toggleMessageBox({
        on: true,
        title: "Delete User",
        message: "You cannot delete yourself, dummy! Nice try though!",
        buttons: ["OK"],
        clicked: ""
      });
      return;
    }

    //Ask for confirmation
    let result = await getMsgBoxResult({
      on: true,
      title: "Confirm Delete User",
      message: `Are you sure you want to delete ${userData.LastName}'s account?`,
      buttons: ["Yes", "No"]
    });

    if(result==="No") return;

    //Confirm again
    result = await getMsgBoxResult({
      on: true,
      title: "Confirm Delete User",
      message: `This action is irreversible. Are you sure you want to delete ${userData.LastName}'s account?`,
      buttons: ["Yes", "Cancel"]
    });

    if(result==="Cancel") return;

    //Proceed to delete

    try{
      const response = await fetch('https://api.autodealerug.com/deleteuser', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          DealershipID: globals.DealershipID,
          UserID: UserID
        }),
        credentials: "include"
      });

      //Check that the response contains JSON, or if it's plain text before parsing
      if (response.headers.get("content-type")?.includes("application/json")) {
        const resObj = await response.json();
        if (response.ok) {
          //Close the modal
          onClose();
        } else {
          //Handle errors 500 and 401 separately
          if (response.status === 401) {
            toggleMessageBox({
                on: true,
                title: "Unauthorized",
                message: "Session Expired. Please login",
                buttons: ["OK"]
            });
            logOut();
          } else {

            toggleMessageBox({
              on: true,
              title: "Fetch Error",
              message: resObj.message,
              buttons: ["OK"],
              clicked: ""
            });
        }
        }
      } else {
        const resText = await response.text();
        toggleMessageBox({
          on: true,
          title: "Fetch Error",
          message: resText,
          buttons: ["OK"],
          clicked: ""
        });
      }
    } catch(err){
      toggleMessageBox({
        on: true,
        title: "Network Error",
        message: err.message,
        buttons: ["OK"],
        clicked: ""
      });
    }
  }

  return ReactDOM.createPortal(
    <Theme>
      <div id="edit-user-account" className="modal-overlay">
        <div className="modal-content">
          <div className="account-header">
            <div className="profile-section">
              <img
                src={profilePic}
                alt="Profile"
                className="profile-pic"
                onClick={handleProfilePicClick} // Make the image clickable
              />
              <input
                type="file"
                accept="image/*"
                onChange={handleProfilePicChange}
                ref={fileInputRef} // Attach the ref
                style={{ display: "none" }} // Hide the input
              />
            </div>
            <h2>Edit User Account</h2>
          </div>
          <div>
            <div>
              <h6>User Info</h6>
            </div>
            <div className="user-info">
              <div className="input-block" style={{gridArea: "1 / 1 / 2 / 3"}}>
                <input className="input" name="UserName" value={userData.UserName} onChange={handleChange} onBlur={handleBlur} placeholder="Username" readOnly={mode==='add'?false:true} />
                {isUnique.UserName===false && <p className='error'>This username is taken. Please choose a different one.</p>}
                {errors.UserName && <p className="error">{errors.UserName}</p>}
              </div>

              <div className="input-block">
                <input className="input" name="FirstName" value={userData.FirstName} onChange={handleChange} onBlur={handleBlur} placeholder="First Name"/>
                {errors.FirstName && <p className="error">{errors.FirstName}</p>}
              </div>
              
              <div className="input-block">
                <input className="input" name="LastName" value={userData.LastName} onChange={handleChange} onBlur={handleBlur} placeholder="Last Name"/>
                {errors.LastName && <p className="error">{errors.LastName}</p>}
              </div>
              <div className="input-block">
                <input className="input" name="Phone1" value={userData.Phone1} onChange={handleChange} onBlur={handleBlur} placeholder="Phone 1"/>
                {errors.Phone1 && <p className="error">{errors.Phone1}</p>}
              </div>

              <div className="input-block">
                <input className="input" name="Phone2" value={userData.Phone2} onChange={handleChange} onBlur={handleBlur} placeholder="Phone 2"/>
                {errors.Phone2 && <p className="error">{errors.Phone2}</p>}
              </div>
            </div>
          </div>
          <div className="user-roles">
            <hr></hr>
            <div><h6>User Roles</h6></div>
            <UserRoles
              UserID = {user?.UserID || null}
              roles={roles}
              handleRoleChange={handleRoleChange}
              mode={mode}
              setRoles={setRoles}
              backupRoles={backupRoles}
              setBackupRoles={setBackupRoles}
            />
          </div>
          <Flex gap="9" justify="center" style={{ marginTop: "10px" }}>
            {mode === "edit" && UserID !== userData.UserID && (
              <Button variant="soft" radius="large" color="red" onClick={() => handleDelete(user.UserID)} style={{ marginRight: "10px" }}>
                Delete
              </Button>
            )}
            <Button variant="soft" radius="large" color="orange" onClick={onClose} style={{ marginRight: "10px" }}>
              {mode === "add" ? "Cancel" : "Close"}
            </Button>
            {mode === 'add' && (
              <Button variant="soft" radius="large" color="blue" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Flex>
        </div>
      </div>
    </Theme>
  , document.body);
};

function UserRoles({ UserID, roles, backupRoles, handleRoleChange, mode, setRoles, setBackupRoles }) {
  const globals = useContext(GlobalContext);
  const canEdit = globals.userData.Permissions.Admin.Granted && globals.userData.UserID !== UserID;
  const ownSheetName = "UserRoles";
  const table_name = "UserRoles"
  const primary_key = "RoleID";
  const { sendOrQueue } = utilities;
  const { socket, toggleMessageBox } = globals;
  const pendingMessages = useRef([]);

  const handleCheckBoxChange = ({ target })=>{
    
    const { name, checked } = target;
    handleRoleChange(name);

    if(mode==="add") return; //No need to update the db
    //Check if the roles have changed
    //If they have, update the db
    if((checked ? true : false) === backupRoles[name].Granted){
      return;
    }
    //Update
    sendOrQueue({
      DealershipID: globals.DealershipID,
      UserID: globals.userData.UserID,
      ownSheetName: ownSheetName,
      table: table_name,
      fieldName: "Granted",
      fieldValue: checked ? -1 : 0,
      primaryKeyName: primary_key,
      primaryKeyValue: roles[target.name].RoleID
    }, socket,pendingMessages.current, roles, backupRoles, setRoles)
  }

  const fieldUpdateListener = (msg)=>{
    console.log(msg);
    if(msg.sheetName!==ownSheetName || msg.ok===false || msg.ok===false){
      //Only handle events for this sheet
      return;
    }
    //Get the Field and Status {ok: true/false, fieldName: fieldName}
    if(msg.ok){ //Update was successful
        //Update the fallback
        const newRoleObject = {
          RoleID: msg.primaryKeyValue,
          Granted: msg.fieldValue===-1? true : false
        }
        setBackupRoles({...backupRoles, [msg.fieldName]: newRoleObject});
        
    } else { //Check and log msg.error
        //Show error and revert to fallback
        setRoles({...roles, [msg.fieldName]:backupRoles[msg.fieldName]});
    }
  }

  useEffect(()=>{
    //Add event listeners to the websocket
    //Return a function to remove them when component dismounts
    if(socket.disconnected){
      console.log("Socket disconnected")
        socket.connect()
    }
    
    socket.on('update_record', fieldUpdateListener);
  
    return ()=>{
        socket.off('update_record',fieldUpdateListener);
    }
  })
  return (
    <div className="roles">
      {Object.keys(roles).map((role) => (
        <label key={role}>
          <input
            disabled={!canEdit}
            name={role}
            type="checkbox"
            checked={roles[role].Granted}
            onChange={handleCheckBoxChange}
            value={roles[role].Granted? true : false}
          />
          {role}
        </label>
      ))}
    </div>
  );
}
export default UserAccountModal;
