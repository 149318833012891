import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Theme } from "@radix-ui/themes";

const TextBox = ({ title, onClick }) => {
    const [ text, setText ] = useState("");
    const handleClick = (button) => {
        if (onClick) onClick(button);
    };
    const handleChange = ({ target })=>{
        setText(target.value)
    }
    return ReactDOM.createPortal(
        <Theme>
        <div className="message-box-overlay">
            <div className="message-box">
                {title && <h3 className="message-box-title">{title}</h3>}
                <textarea className="textbox-textarea" onChange={handleChange} value={text}>

                </textarea>
                <div className="message-box-buttons">
                    <button 
                            key="cancel" 
                            onClick={() => handleClick("CANCEL")}
                            className={`message-box-button cancel`}
                        >
                            Cancel
                    </button>

                    <button 
                            key="ok" 
                            onClick={() => handleClick(text)}
                            className={`message-box-button yes`}
                        >
                            OK
                    </button>
                </div>
            </div>
        </div>
        </Theme>,
        document.body
    );
};

export default TextBox;
