import React, { useState, useContext, useEffect } from "react";
import AddEditInventory from "./AddEditInventory";
import utilities from "./UtilityFunctions";
import CommonThead from "./CommonThead";
import { GlobalContext } from "./Cars";
import CommonTBody from "./CommonTBody";
import { Button } from "@radix-ui/themes";
import { Card, DonutChart } from '@tremor/react';

function Inventory({ cars, setCars }){
    const ownSheetName = "Inventory";
    const [ modalVisible, setModalVisibility ] = useState({
        visible: false,
        mode: "add"
    });
    const [ toggleReload, setToggleReload ] = useState(true);

    const [ sortOrder, setSortOrder ] = useState(()=>{
        const sortObj = {};
        cars && cars[0] && Object.keys(cars[0]).forEach((propertyName)=>{
            sortObj[propertyName] = "none"; //Other values will be "asc" and "desc"
        });
        return sortObj;
    })

    const [ filterObject, setFilterObject ] = useState({});

    const sortCars = (field)=>{
        setSortOrder((prev)=>{
            setCars((prevCars)=>{
                return  utilities.sortArray(prevCars, field, prev[field]==="asc"?"desc":"asc")
            });
            return {...prev, [field]: prev[field]==="asc"?"desc":"asc"}
        })
        setToggleReload(prev=>!prev);
    }

    const filterData = (fieldName, filterType, lowerValue, upperValue)=>{
        console.log(fieldName, filterType, lowerValue, upperValue);
        
        setFilterObject(prev=>{
            if(!filterType) return {}; //This way, just call with only first argument to clear the filter
            return {...prev, [fieldName]: { filterType, lowerValue, upperValue }}
        });
    }

    return (
        <div className="page">
            <Content 
                modalVisible={modalVisible} 
                setModalVisibility={setModalVisibility} 
                cars={cars} setCars={setCars} sortCars={sortCars}  
                sortOrder={sortOrder} parentSheetName={ownSheetName} 
                filterData={filterData} filterObject={filterObject}
                key={cars.length}
            />
            <Summary setModalVisibility={setModalVisibility} cars={cars} />
        </div>
    )
}

function Content({ cars, setCars, modalVisible, setModalVisibility, sortCars, sortOrder, parentSheetName, filterData, filterObject}){
    const FieldOrder = useContext(GlobalContext).fieldOrder;
    const [ fieldOrder, setFieldOrder ] = useState((()=>{
        const tmpFieldOrder = FieldOrder? FieldOrder[parentSheetName] : null;
        if(!tmpFieldOrder?.length){ //0 or undefined. Build one
            let ownFields = cars && cars[0] && Object.getOwnPropertyNames(cars[0]); //Fields of first row
            ownFields = ownFields?ownFields:[]; //If undefined, return an empty array

            const defaultFieldOrder = ownFields.map((strField)=>{
                //Return an object {name:name,hidden:false}
                return {name: strField, hidden: false};
            });
            
            return defaultFieldOrder;
        }
        return tmpFieldOrder;
    })()); //Get initial from globals
    const [ currentVehicleIndex, setCurrentVehicle ] = useState();
    const openModalEdit = (vehicleId)=>{
        setCurrentVehicle(vehicleId);
        setModalVisibility({
            visible: true,
            mode: "edit"
        });
    }
    const [ selectedRow, setSelectedRow ] = useState("")
    const selectRow = (rowIndex)=>{
        setSelectedRow(rowIndex)
    }

    const formattingRules = {
        Days: {
            formatByValue: (val)=>{
                if(val > 240 && val <= 270){
                    return {color: "orange", fontWeight: "bold",textAlign: "center"}
                }
                if(val > 270){
                    return {color: "red", fontWeight: "bold",textAlign: "center"}
                }
                return {color: "green", fontWeight: "bold",textAlign: "center"}
            }
        }
    }

    return (
        <div className="content">
            <table className="table table-striped table-hover table-sm" >
                <CommonThead
                    objectsArray={cars}
                    sortObjectsArray={sortCars}
                    sortOrder={sortOrder}
                    fieldOrder={fieldOrder}
                    setFieldOrder={setFieldOrder}
                    parentSheetName={parentSheetName}
                    filterData={filterData}
                    filterObject={filterObject}
                />
                <CommonTBody
                    objectsArray={cars}
                    filterObject={filterObject}
                    selectRow={selectRow}
                    selectedRow={selectedRow}
                    fieldOrder={fieldOrder}
                    openModalEdit={openModalEdit}
                    clickableField={"ChassisNo"}
                    primaryKeyName={"VehicleID"}
                    formattingRules={formattingRules}
                />
            </table>
            {
                modalVisible.visible &&  (modalVisible.mode==="edit" ? (cars[currentVehicleIndex] || false) : true) &&
                <AddEditInventory 
                    formMode={modalVisible.mode} 
                    showModal={setModalVisibility} 
                    car={cars[currentVehicleIndex]} 
                    parentSheetName={parentSheetName} 
                    //In add mode, read the origin that the user selected. In edit mode, read the origin from the record's origin field
                    origin={modalVisible.mode==="add" ? modalVisible.origin : cars[currentVehicleIndex].Origin}
                />
            }

        </div>
    )
}

function Summary({ setModalVisibility, cars}){
    const { getMsgBoxResult } = useContext(GlobalContext);
    const [ data, setData ] = useState([]);
    
    useEffect(()=>{
        const numberOfCars = cars.length;
        const numberOfLocal = cars.filter((car)=>car.Origin==="Local").length;
        const numberOfImported = numberOfCars - numberOfLocal;
        const data = [];
        //Reduce data to return an array with total number of vehicles and total value, split into categories "Local" and "Imported"
        data.push({
            "name": "Local",
            "numbers": numberOfLocal,
            "share": `${utilities.formatIntoInput((numberOfLocal/numberOfCars*100).toFixed(0), "decimal")} %`,
            href: '#',
            borderColor: 'bg-blue-500',
        });
        data.push({
            "name": "Imported",
            "numbers": numberOfImported,
            "share": `${utilities.formatIntoInput((numberOfImported/numberOfCars*100).toFixed(0), "decimal")} %`,
            href: '#',
            borderColor: 'bg-fuchsia-500',
        });
        setData(data);
    },[cars]);
    const addNewVehicle = async ()=>{
        const origin = await getMsgBoxResult({
            on: true,
            title: "Vehicle Origin",
            message: "Is the vehicle registered, or in the bond?",
            buttons: ["Registered", "Bond"]
        });
        
        setModalVisibility({
            visible: true,
            mode: "add",
            origin: origin==="Registered"?"Local":"Imported"
        })
    }
    return (
        <div className="summary">
            <Button 
                color="blue"
                variant="outline"
                size="2"
                radius="medium"
                onClick={addNewVehicle}
            >Add New</Button>
            <Donut data={data} />
            
        </div>
    )
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Donut({ data }) {
  return (
    <>
      <Card style={{width:'400px', margin: '0px 20px'}} className="sm:mx-auto sm:max-w-xl h-20 p-0 border-0 shadow-none">
        <div className="mt-2 grid grid-cols-1 gap-0 sm:grid-cols-2 sm:gap-4">
          <DonutChart
            data={data}
            category="numbers"
            index="name"
            valueFormatter={(number)=>{return `${number}`}}
            showTooltip={false}
            className="h-16"
            colors={['blue', 'fuchsia']}
          />
          <div className="mt-0 flex items-center sm:mt-0">
            <ul role="list" className="flex space-x-4">
              {data.map((item) => (
                <li key={item.name} className="flex space-x-2 ">
                  <span
                    className={classNames(
                      item.borderColor,
                      'w-2 shrink-0 rounded',
                    )}
                  />
                  <div>
                    <p className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong text-xs">
                      {item.numbers}{' '}
                      <span className="font-normal">({item.share})</span>
                    </p>
                    <p className="mt-0.5 whitespace-nowrap text-tremor-default text-tremor-content dark:text-dark-tremor-content text-xs">
                      {item.name}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Card>
    </>
  );
}


export default Inventory;