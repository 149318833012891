import React, { useState, useEffect, useContext, useRef } from "react";
import { GlobalContext } from "./Cars";
import utilities from "./UtilityFunctions"
import fields from "./fields";

function Comments({ VehicleID, parentSheetName }){
    const ownSheetName = "Comments"
    const globals = useContext(GlobalContext);
    const { toggleMessageBox } = globals;
    const [ comments, setComments ] = useState([]); 
    const [ currCommentIndex, setCurrCommentIndex ] = useState(1);
    const [ modalVisible, setModalVisibility ] = useState({
        visible: false,
        mode: "edit"
    });
    const [ fetchSwitch, setFetchSwitch ] = useState(true);
    

    const openModal = (commentID)=>{
        setCurrCommentIndex(commentID);
        setModalVisibility({
            visible: true,
            mode: "edit"
        });
    }

    const fetchComments = async (_VehicleID)=>{
        try {
            const res = await fetch("https://api.autodealerug.com/getcomments", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify({
                    DealershipID: globals.DealershipID,
                    VehicleID: _VehicleID,
                    ownSheetName,
                })
            });
    
            const responsePayload = await res.json()
    
            const returnObj = {};
            
            if(res.ok) { //2XX
                returnObj.ok = true;
                returnObj.message = responsePayload.data;
                //console.log(returnObj);
                return returnObj;
            } else { //4XX, 5XX TODO: Handle specifics later. Log for now
                console.log("Error: " + responsePayload.message);
                returnObj.ok = false;
                returnObj.message = responsePayload.message;
                console.log(returnObj)
                return returnObj;
            }
        } catch(err) { //Probably the network. TODO: Handle the specifics
            console.log(err.message);
            toggleMessageBox({
                on: true,
                title: "Error",
                message: err.message,
                buttons: ["OK"],
                clicked: ""
            })
        }
    }

    useEffect(()=>{
        //Fetch comments for this car using its VehicleID
        fetchComments( VehicleID ).then(
            (jsonObj)=>{
                if(jsonObj.ok){ //Data, baby! Set the state
                    setComments(jsonObj.message);
                } else {
                    toggleMessageBox({
                        on: true,
                        title: "Error",
                        message: jsonObj.message,
                        buttons: ["OK"],
                        clicked: ""
                    })
                }
            }
        ).catch(
            (err)=>{
                toggleMessageBox({
                    on: true,
                    title: "Error",
                    message: err.message,
                    buttons: ["OK"],
                    clicked: ""
                })
            }
        )
    },[fetchSwitch])

    const addNewComment = (e)=>{
        e.preventDefault();
        //Open the modal in "add mode"
        setModalVisibility({
            visible: true,
            mode: "add"
        });
    }

    return(
        <div className="other-comments-table-container">
            <table className="table table-striped table-hover table-sm" >
                <thead>
                    <tr>
                        {
                            comments && comments[0] && Object.keys(comments[0]).map((val, ind,arr)=>{
                                if(fields[val].public){
                                    return (
                                        <th key={val}>
                                            {fields[val].displayName}
                                        </th>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                        {
                            comments && comments.map((obj, rowindex, arr)=>{
                                return (<tr key={rowindex}>
                                    {
                                        Object.keys(obj).map((propty, fieldindex,arrd)=>{
                                            if(fields[propty].public){
                                                if(propty==="UserName"){
                                                    return(
                                                        <td key={fieldindex}>
                                                            <a href="#" onClick={(e)=>{
                                                                e.preventDefault();
                                                                return openModal(rowindex)
                                                            }}>
                                                            {utilities.formatIntoInput(obj[propty], fields[propty].dataType)}
                                                            </a>
                                                        </td>
                                                    );
                                                } else {
                                                    return(
                                                        <td key={fieldindex}>
                                                            {utilities.formatIntoInput(obj[propty], fields[propty].dataType)}
                                                        </td>
                                                    );
                                                }

                                            }
                                        })
                                    }
                                </tr>)
                            })
                        }
                </tbody>
            </table>
            <div className="btn-container">
                <button onClick={addNewComment} className="btn btn-secondary">
                    Add Comment
                </button>
            </div>
            {
                modalVisible.visible &&  <AddEditComment 
                mode={modalVisible.mode}
                initcommentData={comments[currCommentIndex]}
                toggleModal={()=>setModalVisibility({
                    visible: false,
                    mode: ""
                })}
                VehicleID={VehicleID}
                ownSheetName={ownSheetName}
                 />
            }
        </div>
    )
}

/**************************************************************************
 *************************************************************************/

function AddEditComment({ mode, initcommentData, toggleModal, VehicleID, parentSheetName }){
    const [ backupcommentData, setBackupcommentData ] = useState((()=>{
        if(mode==="add"){
            return (
                {
                    UserComment: ""
                }
            );
        } else {
            Object.keys(initcommentData).forEach((fieldName, value, array)=>{
                initcommentData[fieldName] = utilities.formatFromInput(initcommentData[fieldName], fields[fieldName].dataType);
            });
            return initcommentData;
        }
    })()); //Format the fields
    const [ commentData, setCommentData ] = useState(backupcommentData);
    const pendingMessages = useRef([]);
    const { sendOrQueue }= utilities;

    //Get the global socket
    const globals = useContext(GlobalContext);
    const { toggleMessageBox } = globals;
    const socket = globals.socket;
    const table_name = "Comments";
    const primary_key = "CommentID";

    const handleSubmit = (e)=>{
        e.preventDefault();
        //In add mode, send a fetch request
        //In edit mode, use websockets. Change button to "Save And Close"
        if(mode==="add"){
            //First check that the necessary data was
            if(!commentData.UserComment){
                toggleMessageBox({
                    on: true,
                    title: "Error",
                    message: "Comment can't be empty",
                    buttons: ["OK"],
                    clicked: ""
                })
                return;
            }            
            fetch("https://api.autodealerug.com/addcomment", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify({
                    ...commentData,
                    CommentDate: utilities.formatFromInput((new Date()).toISOString(),"date"),
                    DealershipID: globals.DealershipID,
                    UserID: globals.userData.UserID,
                    VehicleID: VehicleID,
                    ownSheetName: parentSheetName
                })
            }).then((res)=>{
                if(res.ok) { //2XX
                    //Close the modal
                    console.log(res.message);
                    toggleModal(); //And refresh
                } else { //4XX, 5XX TODO: Handle specifics later. Log for now
                    console.log("Error: " + JSON.stringify(res));
                    toggleMessageBox({
                        on: true,
                        title: "Error",
                        message: "Server error: " + res.message,
                        buttons: ["OK"],
                        clicked: ""
                    })   
                }
            }).catch((err)=>{
                toggleMessageBox({
                    on: true,
                    title: "Error",
                    message: "Network error or server down: " + err.message,
                    buttons: ["OK"],
                    clicked: ""
                })
            })
        } else { //Just close
            toggleModal();
        }
    }

    const handleChange = ({ target })=>{
        //Check against the regex if it exists
        const regexx = fields[target.name].regex || null
        if(regexx && !(new RegExp(regexx)).test(target.value)){ //If it fails the regex test
            //target.style.backgroundColor = "#885555"
        } else {
            setCommentData((prev)=>{
                return {...prev, [target.name]: utilities.formatFromInput(target.value, fields[target.name].dataType)}
            })
        }
    }

    const handleBlur = ({ target })=>{
                //In add mode: Set the state. 
        //In edit mode: update with websockets AND set the state
        if(mode==="add"){
            setCommentData((prev)=>{
                return {...prev, [target.name]: utilities.formatFromInput(target.value, fields[target.name].dataType)}
            })
        } else {
            //If it's a number, remove commas
            let newVal = utilities.formatFromInput(target.value, fields[target.name].dataType);
            let prevVal = utilities.formatFromInput(backupcommentData[target.name], fields[target.name].dataType);
            //console.log(`New val: ${newVal}, Prev val: ${prevVal}`);
            if(newVal!=prevVal) { //It has been changed
                const msg = {
                    DealershipID: globals.DealershipID,
                    UserID: globals.userData.UserID,
                    table: table_name,
                    ownSheetName: parentSheetName,
                    primaryKeyName: primary_key,
                    primaryKeyValue: commentData.CommentID,
                    fieldName: target.name,
                    fieldValue: newVal,
                    triggerRefresh: fields[target.name].triggerRefresh
                };

                sendOrQueue(msg, socket, pendingMessages.current, commentData, backupcommentData, setCommentData);
            }
        }

    }

    const handleCancel = ()=>{
        toggleModal();
    }
    const fieldUpdateListener =  (msg)=>{
        if(msg.sheetName!==parentSheetName){
            //Only handle events for this sheet
            return;
          }
        console.log(msg);
        //Get the Field and Status {ok: true/false, fieldName: fieldName}
        if(msg.ok){ //Update was successful
            //Update the fallback, then refresh
            setBackupcommentData({...backupcommentData, [msg.fieldName]: commentData[msg.fieldName]});
        } else { //Check and log msg.error
            //Show error and revert to fallback
            setCommentData({...commentData, [msg.fieldName]:backupcommentData[msg.fieldName]});
        }
    }
    const ioReconnectListener = ()=>{
        let attempts = 10;
        let currMsg = {};
        //Check the queue for any messages for transmission
        while(pendingMessages.current.length>0) {
            console.log(`${pendingMessages.current.length} messages in queue`);
            //Limit no. of attempts to 100 to prevent endless loop
            currMsg = pendingMessages.current.shift();
            sendOrQueue(currMsg, socket, pendingMessages.current, commentData, backupcommentData, setCommentData);
            if(attempts===0){
                break;
            }
            attempts--;
        }
    }
    useEffect(()=>{
        //Add event listeners to the websocket
        //Return a function to remove them when component dismounts
        if(socket.disconnected){
            socket.connect()
        }
        socket.on('update_record',fieldUpdateListener);

        socket.on('connect',ioReconnectListener);

        return ()=>{
            socket.off('update_record',fieldUpdateListener);
            socket.off('connect',ioReconnectListener)
        }
    },[]);


    return (
        <div className="form-modal top-tier">
            <div className="add-comment-dialogue">
                <div className="form-comments">
                    <label htmlFor="UserComment">{fields.UserComment.displayName}</label>
                    <textarea onBlur={handleBlur} className="form-control" onChange={handleChange} type="text" name="UserComment" value={commentData.UserComment}></textarea>
                    
                    <div className="frm-btn-container">
                        <button className="btn btn-warning"
                            onClick={handleCancel}
                        >Cancel</button>

                        <button className="btn btn-primary"
                            onClick={handleSubmit}
                        >{mode==="add"?"Submit":"Save And Close"}</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Comments;