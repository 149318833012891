import React, { useState, useEffect, useContext } from "react";
import AddEditBroker from "./AddEditBroker";
import fields from "./fields"
import utilities from "./UtilityFunctions";
import CommonThead from "./CommonThead";
import { GlobalContext } from "./Cars";
import CommonTBody from "./CommonTBody";
import { Button } from "@radix-ui/themes";


function Brokers({ brokers=[], setBrokers }){
    const ownSheetName = "Brokers";
    const [ modalVisible, setModalVisibility ] = useState({
        visible: false,
        mode: "add"
    });
    const [ toggleReload, setToggleReload ] = useState(true);
    const [ sortOrder, setSortOrder ] = useState(()=>{
        const sortObj = {};
        Object.keys(fields).forEach((propertyName)=>{
            sortObj[propertyName] = "none"; //Other values will be "asc" and "desc"
        });
        return sortObj;
    })
    const sortBrokers = (field)=>{
        setSortOrder((prev)=>{
            setBrokers((prevBrokers)=>{
                return  utilities.sortArray(prevBrokers, field, prev[field]==="asc"?"desc":"asc")
            });
            return {...prev, [field]: prev[field]==="asc"?"desc":"asc"}
        })
        setToggleReload(prev=>!prev);
    }
    const [ filterObject, setFilterObject ] = useState({});
    const filterData = (fieldName, filterType, lowerValue, upperValue)=>{
        console.log(fieldName, filterType, lowerValue, upperValue);
        setFilterObject(prev=>{
            return {...prev, [fieldName]: { filterType, lowerValue, upperValue }}
        });
    }
    return (
        <div className="page">
            <Content 
                modalVisible={modalVisible} 
                setModalVisibility={setModalVisibility} 
                brokers={brokers} 
                setBrokers={setBrokers} 
                sortBrokers={sortBrokers}  
                sortOrder={sortOrder}
                parentSheetName={ownSheetName}
                filterData={filterData}
                filterObject={filterObject}
            />
            <Summary setModalVisibility={setModalVisibility} brokers={brokers} />
        </div>
    )
}

function Content({ brokers, setBrokers, modalVisible, setModalVisibility, sortBrokers, sortOrder, parentSheetName, filterData, filterObject }){
        const FieldOrder = useContext(GlobalContext).fieldOrder; 
            const [ fieldOrder, setFieldOrder ] = useState((()=>{
                const tmpFieldOrder = FieldOrder[parentSheetName];
                if(!tmpFieldOrder?.length){ //0 or undefined. Build one
                    let ownFields = brokers && brokers[0] && Object.getOwnPropertyNames(brokers[0]); //Fields of first row
                    ownFields = ownFields?ownFields:[]; //If undefined, return an empty array
        
                    const defaultFieldOrder = ownFields.map((strField)=>{
                        //Return an object {name:name,hidden:false}
                        return {name: strField, hidden: false};
                    });
                    
                    return defaultFieldOrder;
                }
                return tmpFieldOrder;
            })()); //Get initial from globals
    const [ currentBrokerIndex, setCurrentBroker ] = useState();
    const openModalEdit = (brokerId)=>{
        setCurrentBroker(brokerId);
        setModalVisibility({
            visible: true,
            mode: "edit"
        });
    }
    const updateBrokersLocally = (newBroker)=>{ //Only update the broker which was updated in the modal
        setBrokers((prev)=>{
            prev.splice(currentBrokerIndex, 1, newBroker);
            return prev;
        });
    }

    const [ selectedRow, setSelectedRow ] = useState("")

    const selectRow = (rowIndex)=>{
        setSelectedRow(rowIndex)
    }

    return (
        <div className="content">
            <table className="table table-striped table-hover table-sm" >
                <CommonThead
                    objectsArray={brokers}
                    sortObjectsArray={sortBrokers}
                    sortOrder={sortOrder}
                    fieldOrder={fieldOrder}
                    setFieldOrder={setFieldOrder}
                    parentSheetName={parentSheetName}
                    filterData={filterData}
                    filterObject={filterObject}
                />
                <CommonTBody
                    objectsArray={brokers}
                    filterObject={filterObject}
                    selectRow={selectRow}
                    selectedRow={selectedRow}
                    fieldOrder={fieldOrder}
                    openModalEdit={openModalEdit}
                    clickableField={"FirstName"}
                    primaryKeyName={"BrokerID"}
                />
            </table>
            {
                modalVisible.visible &&  <AddEditBroker 
                formMode={modalVisible.mode} 
                showModal={setModalVisibility} 
                brokerData={brokers[currentBrokerIndex]} 
                updateBrokersLocally={updateBrokersLocally}
                parentSheetName={parentSheetName} />
            }

        </div>
    )
}

function Summary({ setModalVisibility, brokers}){
    return (
        <div className="summary">
            <Button
                color="blue"
                variant="outline"
                size="2"
                radius="medium"
                onClick={()=>setModalVisibility({
                    visible: true,
                    mode: "add"
                })}
            >Add New</Button>

            <div className="aggregate">
                <div>Total Number:</div><div>{brokers.length}</div>
                <div>Todo:</div><div>Todo</div>
            </div>
        </div>
    )
}

export default Brokers;