import React, { useState } from "react";
import Navtabs from "./NavTabs";
import SubNav from "./SubNav";

function CarsNav({ logInStatus, setLogInStatus }){
    const [ activeTab, setActiveTab ] = useState("tab-inventory");
    const [activeSubMenu, setActiveSubMenu] = useState(()=>{
        switch(activeTab){ //Set default active submenus for Inventory
            case "tab-inventory":
                return "Inventory"; 
            case "tab-sales":
                return "Sales";
            case "tab-logistics":
                return "NewPurchase";
            case "tab-customers":
                return "Customers";
            case "tab-brokers":
                return "Brokers";
            default:
                return "";
        }
    })    
    const switchActiveSubMenu = (newSubMenu)=>{
        setActiveSubMenu(newSubMenu);
    }

    const onTabClick = ({ target })=>{
        setActiveTab(target.id);
        setActiveSubMenu(()=>{
            switch(target.id){ //Set default active submenus for Inventory
                case "tab-inventory":
                    return "Inventory"; 
                case "tab-sales":
                    return "Credit";
                case "tab-logistics":
                    return "NewPurchase";
                case "tab-customers":
                    return "Customers";
                case "tab-brokers":
                    return "Brokers";
                default:
                    return "";
            }            
        })
    }

    return (
        <div className="carsnav">
            <Navtabs activeTab={activeTab} onTabClick={onTabClick} />
            <SubNav activeTab={activeTab}  activeSubMenu={activeSubMenu} switchActiveSubMenu={switchActiveSubMenu}
                    setLogInStatus={setLogInStatus} logInStatus={logInStatus}/>
        </div>
    )
}

export default CarsNav;