import React, { useState, useEffect, createContext, useRef } from "react";
import CarsNav from "./CarsNav";
import CarsHead from "./CarsHead"
import io from "socket.io-client";
import MessageBox from "./MessageBox";
import TextBox from "./TextBox";
import { emitter } from './UtilityFunctions';
import RegistrationForm from "./RegistrationForm";
import LogIn from "./LogIn";
import { Spinner } from "@radix-ui/themes";

export const GlobalContext = createContext();

function Cars(){
    //DealershipID, UserID, FirstName, LastName, Phone1, Phone2, UserName, Preferences
    const [ logInStatus, setLogInStatus ] = useState("wait"); //true | login | register

    const [ socket, setSocket ] = useState(null);
    const globals = useRef({
        vatRate: 1.18,    
    });

    const setUserData =  (data)=>{
      globals.current.DealershipID = data.DealershipID;
      globals.current.UserID = data.UserID;
      globals.current.UserName =  data.UserName;
      globals.current.userData = {
        UserID: data.UserID,
        UserName: data.UserName,
        FirstName: data.FirstName,
        LastName: data.LastName,
        Phone1: data.Phone1,
        Phone2: data.Phone2,
        Permissions: data.Permissions
      };
      globals.current.fieldOrder = data.Preferences;
      globals.current.companyData = {
        CompanyName: data.CompanyName,
        ContactPhone1: data.ContactPhone1,
        ContactPhone2: data.ContactPhone2,
        BondName: data.BondName,
        MessagesAccount: data.MessagesAccount,
        MessagesPassword: data.MessagesPassword,
        DailyPenalty: data.DailyPenalty,
        validity: data.Validity
      }
    }
    const serverDisconnectHandler = ()=>{
        //show the messagebox
        console.log("Event triggered.")
        toggleMessageBox({
            on: true,
            title: "No Connection",
            message: "Either no internet, or the server is down.",
            buttons: ["OK"],
            clicked: ""
        });
    }
    useEffect(()=>{
        //If login status changes to true, update socket
        //This time include auth in the options object
        if(logInStatus!==true) return;
        
        //Reconnect with the new auth
        setSocket(()=>{
            const newSock = io('wss://api.autodealerug.com',
                {
                    autoConnect: true,       // Ensures the socket connects automatically
                    reconnection: true,      // Allows reconnection attempts
                    reconnectionAttempts: 25, // Number of attempts before giving up
                    reconnectionDelay: 2000,          // Wait 2 seconds before the first attempt
                    auth: {
                        DealershipID: globals.current.DealershipID,
                        UserID: globals.current.UserID
                    }
                }
            );
            return newSock;
        });
    },[logInStatus])

    useEffect(()=>{ //Get the userdata
        //Listen for disconnection events and show a message
        emitter.on("server-disconnected",serverDisconnectHandler);
        getUserData();

        //Get user data from db. TODO: This will be got from the login process

        return ()=>{
            emitter.removeListener("server-disconnected",serverDisconnectHandler)
        }
    },[]);

//Message Box
    const [ msgBoxStatus, toggleMessageBox ] = useState({
        on: false,
        title: "",
        message: "",
        buttons: ""
    });
    const [ textBoxStatus, toggleTextBox ] = useState(
        {
            on: false,
            title: ""
        }
    )
    const [ resolver, setResolver ] = useState();

    const handleMsgBoxClick = (value)=>{
        toggleMessageBox((prev)=>{
            resolver && resolver(value);
            return {...prev, on: false}
        })
        //
    }
    const getMsgBoxResult = (msgBoxState)=>{
        return new Promise((resolve)=>{
            setResolver(()=>resolve);
            toggleMessageBox(msgBoxState);
        })
    }

    const handleTextBoxClick = (value)=>{
        toggleTextBox(prev=>{
            resolver && resolver(value);
            return {...prev, on: false};
        })
    }
    const asyncOpenTextBox = (textBoxState)=>{
        return new Promise((resolve)=>{
            setResolver(()=>resolve);
            toggleTextBox(textBoxState);
        });
    }
    const getUserData = ()=>{
        fetch('https://api.autodealerug.com/getuserdata',{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({})
        }).then(async (res)=>{
            // console.log(res);
            switch(res.status){
                case 200: //OK
                    const resObj = await res.json();
                    setLogInStatus(true);
                    //User preferences come as a string. Parse it into an object
                    let prefs = resObj.userData.Preferences;
                    if(!prefs){
                        prefs = {};
                    } else {
                        Object.keys(prefs)
                        for(let sheetname of Object.keys(prefs)){
                            prefs[sheetname] = JSON.parse(prefs[sheetname]);
                        }     
                    }
                    resObj.userData.Preferences = prefs;

                    setUserData(resObj.userData);
                    //Now we have the DealershipID, set the socket
                    break;
                case 500: //Server error\
                    const resObj2 = await res.json();
                    console.log(`Server Error: ${resObj2.message}`);
                    alert("Server error: "+resObj2.message);
                    //TODO: Show a proper error message
                    break;
                case 401: //Unauthorised. Not logged in
                    setLogInStatus("login");
                    break;
                default:
                    console.error(`Unexpected response status: ${res.status}`);
            }
        }).catch((err)=>{
            console.log(err);
            alert(`Server connection error. Please check your internet connection: ${err.message}`);
        });
    }
//Message Box
    return (
        <GlobalContext.Provider value={{...globals.current, socket, msgBoxStatus, toggleMessageBox, getMsgBoxResult, asyncOpenTextBox, logOut : ()=>setLogInStatus("login"), globalRef: globals}} >
            <div className="cars">
                {logInStatus===true? <CarsHead />:<div></div>}{/**Just to occupy the top half (Head) of the flex box */}
                {logInStatus===true && <CarsNav setLogInStatus={setLogInStatus} logInStatus={logInStatus} />}
                {logInStatus==="login" && <LogIn setLogInStatus={setLogInStatus} setUserData={setUserData}/>}
                {logInStatus==="register" && <RegistrationForm setLogInStatus={setLogInStatus} />}
                {logInStatus==="wait" && <Spinner size="3">Please wait...</Spinner>}
                {msgBoxStatus.on && <MessageBox
                    title={msgBoxStatus.title}
                    message={msgBoxStatus.message}
                    buttons={msgBoxStatus.buttons}
                    onClick={handleMsgBoxClick}

                />}
                {textBoxStatus.on && <TextBox
                    title={textBoxStatus.title}
                    onClick={handleTextBoxClick}  
                />}
            </div>
        </GlobalContext.Provider>
    );
}

export default Cars;

//Red shoe
//Frozen