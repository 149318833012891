import React, { useState, useEffect, useContext } from "react";
import AddEditSeller from "./AddEditSeller";
import fields from "./fields"
import utilities from "./UtilityFunctions";
import CommonThead from "./CommonThead";
import { GlobalContext } from "./Cars";
import CommonTBody from "./CommonTBody";
import { Button } from "@radix-ui/themes";

function Sellers({ sellers=[], setSellers }){
    const ownSheetName = "Sellers";
    const [ modalVisible, setModalVisibility ] = useState({
        visible: false,
        mode: "add"
    });
    const [ toggleReload, setToggleReload ] = useState(true);
    const [ sortOrder, setSortOrder ] = useState(()=>{
        const sortObj = {};
        Object.keys(fields).forEach((propertyName)=>{
            sortObj[propertyName] = "none"; //Other values will be "asc" and "desc"
        });
        return sortObj;
    })
    const sortSellers = (field)=>{
        setSortOrder((prev)=>{
            setSellers((prevSellers)=>{
                return  utilities.sortArray(prevSellers, field, prev[field]==="asc"?"desc":"asc")
            });
            return {...prev, [field]: prev[field]==="asc"?"desc":"asc"}
        })
        setToggleReload(prev=>!prev);
    }
    const [ filterObject, setFilterObject ] = useState({});
    const filterData = (fieldName, filterType, lowerValue, upperValue)=>{
        console.log(fieldName, filterType, lowerValue, upperValue);
        setFilterObject(prev=>{
            return {...prev, [fieldName]: { filterType, lowerValue, upperValue }}
        });
    }
    return (
        <div className="page">
            <Content 
                modalVisible={modalVisible} 
                setModalVisibility={setModalVisibility} 
                sellers={sellers} 
                setSellers={setSellers} 
                sortSellers={sortSellers}  
                sortOrder={sortOrder}
                parentSheetName={ownSheetName}
                filterData={filterData}
                filterObject={filterObject}
            />
            <Summary setModalVisibility={setModalVisibility} sellers={sellers} />
        </div>
    )
}

function Content({ sellers, setSellers, modalVisible, setModalVisibility, sortSellers, sortOrder, parentSheetName, filterData, filterObject }){
    const FieldOrder = useContext(GlobalContext).fieldOrder; 
    const [ fieldOrder, setFieldOrder ] = useState((()=>{
        const tmpFieldOrder = FieldOrder[parentSheetName];
        if(!tmpFieldOrder?.length){ //0 or undefined. Build one
            let ownFields = sellers && sellers[0] && Object.getOwnPropertyNames(sellers[0]); //Fields of first row
            ownFields = ownFields?ownFields:[]; //If undefined, return an empty array

            const defaultFieldOrder = ownFields.map((strField)=>{
                //Return an object {name:name,hidden:false}
                return {name: strField, hidden: false};
            });
            
            return defaultFieldOrder;
        }
        return tmpFieldOrder;
    })()); //Get initial from globals
    const [ currentSellerIndex, setCurrentSeller ] = useState();
    const openModalEdit = (sellerId)=>{
        setCurrentSeller(sellerId);
        setModalVisibility({
            visible: true,
            mode: "edit"
        });
    }
    const updateSellersLocally = (newSeller)=>{ //Only update the seller which was updated in the modal
        setSellers((prev)=>{
            prev.splice(currentSellerIndex, 1, newSeller);
            return prev;
        });
    }

    const [ selectedRow, setSelectedRow ] = useState("")

    const selectRow = (rowIndex)=>{
        setSelectedRow(rowIndex)
    }

    return (
        <div className="content">
            <table className="table table-striped table-hover table-sm" >
                <CommonThead
                    objectsArray={sellers}
                    sortObjectsArray={sortSellers}
                    sortOrder={sortOrder}
                    fieldOrder={fieldOrder}
                    setFieldOrder={setFieldOrder}
                    parentSheetName={parentSheetName}
                    filterData={filterData}
                    filterObject={filterObject}
                />
                <CommonTBody
                    objectsArray={sellers}
                    filterObject={filterObject}
                    selectRow={selectRow}
                    selectedRow={selectedRow}
                    fieldOrder={fieldOrder}
                    openModalEdit={openModalEdit}
                    clickableField={"FirstName"}
                    primaryKeyName={"SellerID"}
                />
            </table>
            {
                modalVisible.visible &&  <AddEditSeller 
                formMode={modalVisible.mode} 
                showModal={setModalVisibility} 
                sellerData={sellers[currentSellerIndex]} 
                updateSellersLocally={updateSellersLocally}
                parentSheetName={parentSheetName} />
            }

        </div>
    )
}

function Summary({ setModalVisibility, sellers}){
    return (
        <div className="summary">
            <Button 
                color="blue"
                variant="outline"
                size="2"
                radius="medium"
                onClick={()=>setModalVisibility({
                    visible: true,
                    mode: "add"
                })}
            >Add New</Button>

            <div className="aggregate">
                <div>Total Number:</div><div>{sellers.length}</div>
                <div>Todo:</div><div>Todo</div>
            </div>
        </div>
    )
}

export default Sellers;