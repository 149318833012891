import { useState, useContext } from "react";
import ReactDOM from "react-dom";
import * as Dialog from "@radix-ui/react-dialog";
import { Button, TextInput, Card } from "@tremor/react";
import { GlobalContext } from "./Cars";

function  ChangePasswordModal({ isOpen, onClose }) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const { toggleMessageBox, logOut, getMsgBoxResult, userData } = useContext(GlobalContext);

  async function handleChangePassword() {
    setError("");
    setSuccess(false);
  
    if (newPassword.length < 8) {
      setError("New password must be at least 8 characters long.");
      return;
    }
  
    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match.");
      return;
    }
  
    try {
      const response = await fetch("https://api.autodealerug.com/changepassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          UserName: userData.UserName,
          oldPassword,
          newPassword,
        }),
      });
  
      const contentType = response.headers.get("content-type") || "";
      if (!contentType.includes("application/json")) {
        const responseText = await response.text();
        throw new Error(`Unable to connect to the database. ${responseText}`);
      }
  
      const resObj = await response.json();
  
      if (response.ok) {
        if (resObj.success) {
          setSuccess(true);
          onClose();
  
          const clickedButton = await getMsgBoxResult({
            on: true,
            title: "Password Changed",
            message: "Please login with your new password.",
            buttons: ["OK"],
          });
  
          if (clickedButton !== "OK") console.error("Yo! WTF are you doing?");
          logOut();
        } else {
          setError(resObj.message || "Password change failed. Please try again.");
        }
      } else {
        if (response.status === 401) {
          toggleMessageBox({
            on: true,
            title: "Session Expired",
            message: "Your session has expired. Please log in again.",
            buttons: ["OK"],
          });
          onClose();
          logOut();
          return;
        }
        setError(resObj.message || "An error occurred while changing the password.");
      }
    } catch (err) {
      setError(err.message || "An unexpected error occurred. Please try again.");
    }
  }  

  return ReactDOM.createPortal(
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Overlay className="fixed inset-0 bg-black/50" />
      <Dialog.Content className="fixed inset-0 flex items-center justify-center p-4">
        <Card className="p-6 w-96 bg-white rounded-lg shadow-md">
          <Dialog.Title className="text-lg font-semibold">Change Password</Dialog.Title>
          <div className="mt-4">
            <TextInput
              type="password"
              placeholder="Old Password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <TextInput
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="mt-2"
            />
            <TextInput
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="mt-2"
            />
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            {success && <p className="text-green-500 text-sm mt-2">Password changed successfully!</p>}
          </div>
          <div className="mt-4 flex justify-end space-x-2">
            <Button variant="secondary" onClick={onClose}>Cancel</Button>
            <Button variant="primary" onClick={handleChangePassword}>Submit</Button>
          </div>
        </Card>
      </Dialog.Content>
    </Dialog.Root>
  , document.body);
}


export default ChangePasswordModal;
