import React, { useState, useEffect, createContext, useContext } from "react";
//import SubMenuTabs from "./SubMenuTabs";
import Inventory from "./Inventory";
import Committed from "./Committed";
//import Cash from "./Cash";
import Settings from "./Settings";
import Credit from "./Credit";
import Customers from "./Customers";
import Sellers from "./Sellers";
import Brokers from "./Brokers";
import fields from "./fields";
import utilities, { emitter } from "./UtilityFunctions";
import { GlobalContext } from "./Cars";
import Dashboard from "./Dashboard";

export const BrokersCustomersSellers = createContext();

function SubNav({ activeTab="tab-inventory", activeSubMenu, switchActiveSubMenu, logInStatus, setLogInStatus }){
    const globals = useContext(GlobalContext);
    const { socket, toggleMessageBox } = globals;
    
    const fetchHandler = async (url)=>{
        try {
            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify({
                    DealershipID: globals.DealershipID,
                    UserID: globals.userData.UserID
                })
            });
            const responsePayload = await res.json();
            const returnObj = {};

            if(res.status===200) { //2XX
                returnObj.ok = true;
                returnObj.message = responsePayload.data;
                //console.log(returnObj);
                return returnObj;
            }else if(res.status===401){
                //setLogInStatus("login");
                return {ok: false, message: "Unauthorised access. Please log in."};
            } else { //4XX, 5XX TODO: Handle specifics later. Log for now
                console.log("Error: " + responsePayload.message);
                returnObj.ok = false;
                returnObj.message = responsePayload.message;
                console.log(returnObj)
                return returnObj;
            }
        } catch(err) { //Probably the network. TODO: Handle the specifics
            console.log(err.message);
            toggleMessageBox({
                on: true,
                title: "Network error or server down",
                message: err.message,
                buttons: ["OK"],
                clicked: ""
            });
            return {ok: false, message: err.message};
        }
    }
/*Inventory, Customers */
    const fetchinventory = async ()=>{
        return await fetchHandler("https://api.autodealerug.com/getinventory");
    }
    const fetchcommitted = async ()=>{
        return await fetchHandler("https://api.autodealerug.com/getcommitted");
    }
    const fetchcredit = async ()=>{
        return await fetchHandler("https://api.autodealerug.com/getcredit");
    }
    const fetchcash = async ()=>{
        return await fetchHandler("https://api.autodealerug.com/getcash");
    }
    const fetchcustomers = async ()=>{
        return await fetchHandler("https://api.autodealerug.com/getcustomers");
    }
    const fetchbrokers = async ()=>{
        return await fetchHandler("https://api.autodealerug.com/getbrokers");
    }
    const fetchsellers = async ()=>{
        return await fetchHandler("https://api.autodealerug.com/getsellers");
    }
    

    const [ inventory, setInventory ] = useState([]);
    const [ committed, setCommitted ] = useState([]);
    const [ credit, setCredit ] = useState([]);
    const [ cash, setCash ] = useState([]);
    const [ customers, setCustomers ] = useState([]);
    const [ brokers, setBrokers ] = useState([]);
    const [ sellers, setSellers ] = useState([]);

    const fetchData = ()=>{
        //Fetch Inventory
        fetchinventory()
        .then((returnObj)=>{
            if(returnObj.ok){
                setInventory(()=>{
                    //Change to appropriate data types from strings
                    returnObj.message.forEach((rowObj)=>{
                        Object.keys(rowObj).forEach((field)=>{
                            rowObj[field] = utilities.formatFromInput(rowObj[field], fields[field].dataType);
                        });
                    })
                    return returnObj.message;
                });
            } else { //4xx, 5xx
                console.log("returnObj:", returnObj);
                toggleMessageBox({
                    on: true,
                    title: "Fetch error",
                    message: returnObj.message,
                    buttons: ["OK"],
                    clicked: ""
                })
    
            }
        })
        .catch((err)=>{
            toggleMessageBox({
                on: true,
                title: "Inventory > fetchinventory > catch",
                message: err.message,
                buttons: ["OK"],
                clicked: ""
            })
        });
    //Fetch Committed
        fetchcommitted()
        .then((returnObj)=>{
            if(returnObj.ok){
                setCommitted(()=>{
                    //Change to appropriate data types from strings
                    //console.log(Object.keys(returnObj.message[0]))
                    returnObj.message.forEach((rowObj)=>{
                        Object.keys(rowObj).forEach((field)=>{
                            rowObj[field] = utilities.formatFromInput(rowObj[field], fields[field].dataType);
                        });
                    })
                    return returnObj.message;
                });
            } else { //4xx, 5xx
                toggleMessageBox({
                    on: true,
                    title: "Fetch error",
                    message: returnObj.message,
                    buttons: ["OK"],
                    clicked: ""
                })
    
            }
        })
        .catch((err)=>{
            toggleMessageBox({
                on: true,
                title: "Inventory > fetchcommitted > catch",
                message: err.message,
                buttons: ["OK"],
                clicked: ""
            })
        });

    //Fetch Cash
        fetchcash()
        .then((returnObj)=>{
            if(returnObj.ok){
                setCash(()=>{
                    //Change to appropriate data types from strings
                    returnObj.message.forEach((rowObj)=>{
                        Object.keys(rowObj).forEach((field)=>{
                            rowObj[field] = utilities.formatFromInput(rowObj[field], fields[field].dataType);
                        });
                    })
                    return returnObj.message;
                });
            } else { //4xx, 5xx
                toggleMessageBox({
                    on: true,
                    title: "Fetch error",
                    message: returnObj.message,
                    buttons: ["OK"],
                    clicked: ""
                })
    
            }
        })
        .catch((err)=>{
            toggleMessageBox({
                on: true,
                title: "Cash > fetchcash > catch",
                message: err.message,
                buttons: ["OK"],
                clicked: ""
            })
        });

    //Fetch Credit
        fetchcredit()
        .then((returnObj)=>{
            if(returnObj.ok){
                setCredit(()=>{
                    //Change to appropriate data types from strings
                    returnObj.message.forEach((rowObj)=>{
                        Object.keys(rowObj).forEach((field)=>{
                            rowObj[field] = utilities.formatFromInput(rowObj[field], fields[field].dataType);
                        });
                    })
                    return returnObj.message;
                });
            } else { //4xx, 5xx
                toggleMessageBox({
                    on: true,
                    title: "Fetch error",
                    message: returnObj.message,
                    buttons: ["OK"],
                    clicked: ""
                })
    
            }
        })
        .catch((err)=>{
            toggleMessageBox({
                on: true,
                title: "Credit > fetchcredit > catch",
                message: err.message,
                buttons: ["OK"],
                clicked: ""
            })
        });

    //Fetch Customers
        fetchcustomers()
        .then((returnObj)=>{
            if(returnObj.ok){
                setCustomers(()=>{
                    returnObj.message.forEach((rowObj)=>{
                        //For each field hange to appropriate data types from strings
                        Object.keys(rowObj).forEach((field)=>{
                            rowObj[field] = utilities.formatFromInput(rowObj[field], fields[field].dataType);
                            //console.log(`IsCompany: ${rowObj.IsCompany}. Data type: ${typeof(rowObj.IsCompany)}`)
                        });
                        //For each row, add CustomerName=IsCompany?CompanyName:CustomerFirstAndLastName
                        rowObj.CustomerName = rowObj.IsCompany?rowObj.CompanyName:`${rowObj.FirstName} ${rowObj.LastName}`;
                    })
                    return returnObj.message;
                });
            } else { //4xx, 5xx
                toggleMessageBox({
                    on: true,
                    title: "Fetch error",
                    message: returnObj.message,
                    buttons: ["OK"],
                    clicked: ""
                })
    
            }
        })
        .catch((err)=>{
            toggleMessageBox({
                on: true,
                title: "Inventory > fetchcustomers > catch",
                message: err.message,
                buttons: ["OK"],
                clicked: ""
            })
        });

    //Fetch Brokers
        fetchbrokers()
        .then((returnObj)=>{
            if(returnObj.ok){
                setBrokers(()=>{
                    returnObj.message.forEach((rowObj)=>{
                        //For each field hange to appropriate data types from strings
                        Object.keys(rowObj).forEach((field)=>{
                            rowObj[field] = utilities.formatFromInput(rowObj[field], fields[field].dataType);
                            //console.log(`IsCompany: ${rowObj.IsCompany}. Data type: ${typeof(rowObj.IsCompany)}`)
                        });                        })
                    return returnObj.message;
                });
            } else { //4xx, 5xx
                toggleMessageBox({
                    on: true,
                    title: "Fetch error",
                    message: returnObj.message,
                    buttons: ["OK"],
                    clicked: ""
                })
    
            }
        })
        .catch((err)=>{
            toggleMessageBox({
                on: true,
                title: "Inventory > fetchcustomers > catch",
                message: err.message,
                buttons: ["OK"],
                clicked: ""
            })
        });

        //Fetch Sellers
        fetchsellers()
        .then((returnObj)=>{
            if(returnObj.ok){
                setSellers(()=>{
                    returnObj.message.forEach((rowObj)=>{
                        //For each field hange to appropriate data types from strings
                        Object.keys(rowObj).forEach((field)=>{
                            rowObj[field] = utilities.formatFromInput(rowObj[field], fields[field].dataType);
                            //console.log(`IsCompany: ${rowObj.IsCompany}. Data type: ${typeof(rowObj.IsCompany)}`)
                        });                        })
                    return returnObj.message;
                });
            } else { //4xx, 5xx
                toggleMessageBox({
                    on: true,
                    title: "Fetch error",
                    message: returnObj.message,
                    buttons: ["OK"],
                    clicked: ""
                })

            }
        })
        .catch((err)=>{
            toggleMessageBox({
                on: true,
                title: "Inventory > fetchcustomers > catch",
                message: err.message,
                buttons: ["OK"],
                clicked: ""
            })
        });
    }

    const recordReplaceCallback = (msg, prev)=>{
        //console.log(`Row: ${JSON.stringify(msg)}`);
        const returnArr = prev.map((row)=>{
            if(row[msg.primaryKeyName]===msg.primaryKeyValue){
                //console.log(`row[msg.primaryKeyName]: ${row[msg.primaryKeyName]},  msg.primaryKeyValue: ${msg.primaryKeyValue}`)
                return msg.parentRecord;
            } 
            return row;
        });            
        return returnArr;
    }

    const recordUpdateCallback = (msg, prev)=>{
        const returnArr = prev.map((row)=>{
            if(row[msg.primaryKeyName]===msg.primaryKeyValue){
                return {...row, [msg.fieldName]: msg.fieldValue};
            } 
            return row;
        });            
        return returnArr;
    }

    const recordDeleteCallback = (msg, prev)=>{
        const returnArr = prev.filter((record)=>{
            return record[msg.primaryKeyName]!==msg.primaryKeyValue
        });
        console.log(returnArr);
        return returnArr;
    }
    useEffect(()=>{
        if(!socket) return; //Ignore if not authenticated

        //console.log("Socket", socket);

        socket.on("connect",()=>{ //Refetch every time the connection is
            fetchData();
        });

        socket.on('add_record', (msg)=>{
            console.log(msg);
            switch(msg.sheetName){
                case "Inventory":
                    setInventory(prev=>{
                        return [...prev, msg.newRecord];
                    });
                    break;
                case "Committed":
                    setCommitted(prev=>{
                        return [...prev, msg.newRecord];
                    });
                    break;
                case "Cash":
                    setCash(prev=>{
                        return [...prev, msg.newRecord];
                    });
                    break;
                case "Credit":
                    setCredit(prev=>{
                        return [...prev, msg.newRecord];
                    });
                    break;
                /*case "CreditCleared":
                    setCreditCleared(prev=>{
                        return [...prev, msg.newRecord];
                    });
                    break;*/
                case "Customers":
                    setCustomers(prev=>{
                        return [...prev, msg.newRecord];
                    });
                    break;
                case "Brokers":
                    setBrokers(prev=>{
                        return [...prev, msg.newRecord];
                    });
                    break;
                case "Sellers":
                    setSellers(prev=>{
                        return [...prev, msg.newRecord];
                    });
                    break;
            }
        });

        socket.on('replace_record', (msg)=>{
            const { sheetName } = msg;
            switch(sheetName){
                case "Inventory":
                    setInventory((prev)=>recordReplaceCallback(msg, prev));
                    break;
                case "Committed":
                    setCommitted((prev)=>recordReplaceCallback(msg, prev));
                    break;
                case "Cash":
                    setCash((prev)=>recordReplaceCallback(msg, prev));
                    break;
                case "Credit":
                    setCredit((prev)=>recordReplaceCallback(msg, prev));
                    break;
            }
        });

        socket.on('update_record', (msg)=>{
            const { sheetName } = msg;
            switch(sheetName){
                case "Inventory":
                    setInventory((prev)=>recordUpdateCallback(msg, prev));
                    break;
                case "Committed":
                    setCommitted((prev)=>recordUpdateCallback(msg, prev));
                    break;
                case "Cash":
                    setCash((prev)=>recordUpdateCallback(msg, prev));
                    break;
                case "Credit":
                    setCredit((prev)=>recordUpdateCallback(msg, prev));
                    break;
                case "Customers":
                    setCustomers((prev)=>recordUpdateCallback(msg, prev));
                    break;

                case "Brokers":
                    setBrokers((prev)=>recordUpdateCallback(msg, prev));
                    break;
                
                case "Sellers":
                    setSellers((prev)=>recordUpdateCallback(msg, prev));
                    break;
            }
        });

        socket.on('delete_record', (msg)=>{
            console.log("Deleting ", msg);
            switch(msg.sheetName){
                case 'Inventory':
                    setInventory((prev)=>recordDeleteCallback(msg, prev));
                    break;
                case "Committed":
                    setCommitted((prev)=>recordDeleteCallback(msg, prev));
                    break;
                case "Cash":
                    setCash((prev)=>recordDeleteCallback(msg, prev));
                    break;
                case "Credit":
                    setCredit((prev)=>recordDeleteCallback(msg, prev));
                    break;
                case "Brokers":
                    setBrokers((prev)=>recordDeleteCallback(msg, prev));
                    break;
                case "Customers":
                    setCustomers((prev)=>recordDeleteCallback(msg, prev));
                    break;
                case "Sellers":
                    setSellers((prev)=>recordDeleteCallback(msg, prev));
                    break;
                
            }
        });

        return ()=>{
            socket.off("connect");
            socket.off('add_record');
            socket.off('delete_record');
            socket.off('replace_record');
            socket.off('update_record');
            socket.disconnect();
        }
    },[socket]);

    useEffect(()=>{
        if(!logInStatus===true) return; //Return when it changes between "login" and "register"
        if(!socket) return; 
        //Fetch
        if(socket && socket.connected) {
            fetchData();    
        } else {
            socket.connect();
        }

    },[logInStatus, socket]);
/*Inventory, customers */

    switch(activeTab){
        
        case "tab-dashboard":
            return (
                <div className="subnav">
                    <div></div>
                    <Dashboard 
                        Inventory={inventory}
                        Committed={committed}
                        Cash={cash}
                        Credit={credit}
                    />
                </div>
            )        
        case "tab-inventory":
            return (
                <div className="subnav">
                    <SubMenuTabsInventory activeSubMenu={activeSubMenu} switchActiveSubMenu={switchActiveSubMenu}/>
                    {activeSubMenu && activeSubMenu==="Inventory" && 
                    <BrokersCustomersSellers.Provider value={{brokers, customers, sellers}} >
                        <Inventory cars={inventory} setCars={setInventory} />
                    </BrokersCustomersSellers.Provider> }
                    {activeSubMenu && activeSubMenu==="InventoryReport" && "Inventory Report"}
                </div>
            )     
        case "tab-sales":
            return (
                <div className="subnav">
                    <SubMenuTabsSales activeSubMenu={activeSubMenu} switchActiveSubMenu={switchActiveSubMenu}/>
                    {activeSubMenu && activeSubMenu==="Committed" && 
                        <BrokersCustomersSellers.Provider value={{brokers, customers, sellers}} >
                            <Committed cars={committed} setCars={setCommitted} />
                        </BrokersCustomersSellers.Provider>}
                    {activeSubMenu && activeSubMenu==="Cash" && 
                        <BrokersCustomersSellers.Provider value={{brokers, customers, sellers}} >
                            <Credit cars={cash} setCars={setCash} />
                        </BrokersCustomersSellers.Provider>}
                    {activeSubMenu && activeSubMenu==="Credit" &&
                        <BrokersCustomersSellers.Provider value={{brokers, customers, sellers}} >
                            <Credit cars={credit} setCars={setCredit} />
                        </BrokersCustomersSellers.Provider>}
                </div>
            )      
        case "tab-logistics":
            return (
                <div className="subnav">
                    LOGISTICS
                </div>
            )     
        case "tab-customers":
            return (
                <div className="subnav">
                    <div>Submenu</div>
                    <Customers customers={customers} setCustomers={setCustomers} />
                </div>
            )     
        case "tab-brokers":
            return (
                <div className="subnav">
                    <div>Submenu</div>
                    <Brokers brokers={brokers} setBrokers={setBrokers} />
                </div>
            )      
        case "tab-sellers":
            return (
                <div className="subnav">
                    <div>Submenu</div>
                    <Sellers sellers={sellers} setSellers={setSellers} />
                </div>
            )   
        case "tab-settings":
            return (
                <div className="subnav">
                    <div></div>
                    <Settings />
                </div>
            )        
        default:
    }
}

function SubMenuTabsInventory({ activeSubMenu, switchActiveSubMenu }){

    return (
        <div className="sub-menu">
            <button className={`sub-menu-tab ${activeSubMenu==="Inventory" && "sub-menu-active"}`} onClick={()=>switchActiveSubMenu("Inventory")}>
                Inventory
            </button>
            <button className={`sub-menu-tab ${activeSubMenu==="InventoryLocal" && "sub-menu-active"}`}  onClick={()=>switchActiveSubMenu("InventoryLocal")}>
                Inventory Local
            </button>
            <button  className={`sub-menu-tab ${activeSubMenu==="InventoryReport" && "sub-menu-active"}`} onClick={()=>switchActiveSubMenu("InventoryReport")}>
                Inventory Report
            </button>
        </div>
    )
}
function SubMenuTabsSales({ activeSubMenu, switchActiveSubMenu }){

    return (
        <div className="sub-menu">
            <button className={`sub-menu-tab ${activeSubMenu==="Committed" && "sub-menu-active"}`} onClick={()=>switchActiveSubMenu("Committed")}>
                Committed
            </button>
            <button className={`sub-menu-tab ${activeSubMenu==="Cash" && "sub-menu-active"}`}  onClick={()=>switchActiveSubMenu("Cash")}>
                Cash
            </button>
            <button  className={`sub-menu-tab ${activeSubMenu==="Credit" && "sub-menu-active"}`} onClick={()=>switchActiveSubMenu("Credit")}>
                Credit
            </button>
        </div>
    )
}

export default SubNav;
/**
 * NEW IDEA
 * When a record is added or updated, when the event is sent, it should include
 * an array of all affected sheets and modules so they can update after 
 * checking if they're included in the array
 * 
 * Field updates
 * If the field is used in calculations, as determined by 
 * fields.field.triggerRefresh, then rather than just broadcast the new
 * value,
 * 1. using the context, ownSheetName, refetch the new Record and
 *  broadcast it with record_updated
 * 2. But also, broadcast update_record 
 * 
 * Else, just broadcast update_record
 * 
 * Row additions
 * If a row is added to say CarPayments, update the context "ownSheetName"
 * by refetching the affected row and broadcasting it with record_updated
 * But at the same time, also update the sheet Payments through record_added
 * And also update the tab CarPayments through record_added
 * A row could be added (and removed) by Committing, Delivering, Clearing Credit
 * 
 */