import React from "react";

function Navtabs({activeTab, onTabClick }){
    const inactiveTabClasses = "navtab";
    const activeTabClasses = "navtab active-nav-tab";

    return (
        <div className="navtabs-container">
            <button className={activeTab==="tab-dashboard"?activeTabClasses:inactiveTabClasses} id="tab-dashboard" onClick={onTabClick}>
                DASHBOARD
            </button>
            <button className={activeTab==="tab-sales"?activeTabClasses:inactiveTabClasses} id="tab-sales" onClick={onTabClick}>
                SALES
            </button>
            <button className={activeTab==="tab-inventory"?activeTabClasses:inactiveTabClasses} id="tab-inventory" onClick={onTabClick}>
                INVENTORY
            </button>
            <button className={activeTab==="tab-logistics"?activeTabClasses:inactiveTabClasses} id="tab-logistics" onClick={onTabClick}>
                LOGISTICS
            </button>
            <button className={activeTab==="tab-customers"?activeTabClasses:inactiveTabClasses} id="tab-customers" onClick={onTabClick}>
                CUSTOMERS
            </button>    
            <button className={activeTab==="tab-brokers"?activeTabClasses:inactiveTabClasses} id="tab-brokers" onClick={onTabClick}>
                BROKERS
            </button>  
            <button className={activeTab==="tab-sellers"?activeTabClasses:inactiveTabClasses} id="tab-sellers" onClick={onTabClick}>
                SELLERS
            </button>  
            <button className={activeTab==="tab-settings"?activeTabClasses:inactiveTabClasses} id="tab-settings" onClick={onTabClick}>
                SETTINGS
            </button>      
        </div>
    )
}

export default Navtabs;