import React, { useState, useEffect } from "react";
//import Testing from "./Testing";
//import WebSocketComponent from "./WebSocketComponent.js";
import Cars from "./Cars.jsx";

function App(){
  return (
    <Cars />
  );
}
export default App;